import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { accountSiteList } from "store/appSelectors";
import ProfileCard from "widgets/cards/profile";
import UserSitesForm, { UserSitesFormType } from "widgets/forms/users/sites";
import GenericButton from "widgets/shared/buttons/generic";
import { SearchInput } from "widgets/shared/inputs/search";

// Define the props for the Role component
interface SitesProps {
  classes?: string[];
  onSubmit?: (formData: UserSitesFormType) => void;
}

const Sites: React.FC<SitesProps> = ({ classes = [], onSubmit }) => {
  const sites = useSelector(accountSiteList, shallowEqual);
  const [filter, setFilter] = useState("");

  const filteredSites = sites.filter((site) =>
    site.name.toLowerCase().includes(filter.toLowerCase())
  );

  const handleSubmit = (formData: UserSitesFormType) => {
    if (onSubmit) {
      onSubmit(formData);
    }
  };
  return (
    <ProfileCard
      title="Select Sites"
      description="Here you add sites"
      extra="bg-white h-full"
      topRight={
        <div className="flex flex-col  gap-2">
          <SearchInput
            onChange={(filters) => {
              setFilter(filters);
            }}
            type="text"
            value={filter}
            sizes="md"
          />
        </div>
      }
    >
      <div className="-mt-8 h-96 w-full overflow-y-auto pb-2">
        <UserSitesForm
          sites={filteredSites}
          values={{
            classes: classes?.includes("All")
              ? sites.map((item) => item.id)
              : classes,
          }}
          submit={handleSubmit}
          onClose={() => {}}
        />
      </div>
    </ProfileCard>
  );
};

export default Sites;
