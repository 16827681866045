import { t } from "i18next";

/*eslint-disable*/
export default function Footer() {
  return (
    <div className="z-[5] mb-6 grid h-full w-full grid-cols-1 lg:grid-cols-2">
      <div className="col-span-1">
        <p className="flex items-center justify-center text-txt-sm text-link dark:text-darkLink md:px-40 lg:justify-start 3xl:px-64">
          ©{new Date().getFullYear()} {t("footer.all.rights")}
        </p>
      </div>
      <div className="col-span-1">
        <ul className="flex items-center justify-center gap-4">
          <li>
            <a
              target="blank"
              href="https://www.atomservices.io/contact#SUPPORT"
              className="text-txt-sm text-link dark:text-darkLink"
            >
              {t("footer.support")}
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://www.atomservices.io/privacy-policy"
              className="text-txt-sm text-link dark:text-darkLink"
            >
              {t("footer.privacy.policy")}
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://www.atomservices.io/terms-and-conditions"
              className="text-txt-sm text-link dark:text-darkLink"
            >
              {t("footer.terms.use")}
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://www.atomservices.io/contact"
              className="text-txt-sm text-link dark:text-darkLink"
            >
              {t("footer.contact.us")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
