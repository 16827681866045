const DropdownButton = (props: { text: string; onClick: () => void }) => {
  return (
    <button
      type="button"
      className={
        " flex w-full rounded-md bg-white px-4 py-2 text-left text-txt-md text-primary transition duration-200 hover:bg-gray-200 active:bg-gray-300 disabled:bg-gray-200 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
      }
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};

export default DropdownButton;
