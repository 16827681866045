import makeApiRequest from "./clients/apiRequest";
import { gateway } from "./clients/baseClient";
import { versionApiPath } from "./common";
import { ENDPOINTS } from "./endpoints";
import { ResponseData } from "./types/api";
import {
  RatesAddBody,
  RatesStructureAddBody,
  RatesUpdateBody,
} from "./types/rates";

export async function ratesStructureList(): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.RATES.RATE_STRUCTURE.LIST}`,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function ratesStructureAdd(
  body: RatesStructureAddBody
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.RATES.RATE_STRUCTURE.ADD}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function ratesRateList(
  rateStructureId: string
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.RATES.RATE.LIST}`,
    body: { rateStructureId: rateStructureId },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function ratesRateGet(rateId: string): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.RATES.RATE.GET.INDEX}`,
    body: { rateId: rateId },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function ratesRateGetActive(
  siteId: string
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.RATES.RATE.GET.ACTIVE}`,
    body: { siteId: siteId },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function ratesRateAdd(body: RatesAddBody): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.RATES.RATE.ADD}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function ratesRateUpdate(
  body: RatesUpdateBody
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.RATES.RATE.UPDATE}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}
