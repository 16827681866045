import { InputHTMLAttributes, forwardRef } from "react";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  labelKey?: string;
  valueKey?: any;
  checked?: boolean;
  extra?: string;
  onChange: (value: any) => void;
  sizes?: "lg" | "md" | "sm";
  returnBoolean?: boolean;
  color?:
    | "red"
    | "blue"
    | "green"
    | "yellow"
    | "orange"
    | "teal"
    | "navy"
    | "lime"
    | "cyan"
    | "pink"
    | "purple"
    | "amber"
    | "indigo"
    | "gray";
  [x: string]: any;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    extra,
    checked,
    onChange,
    color,
    id,
    labelKey,
    valueKey,
    sizes = "lg",
    returnBoolean = false,
    ...rest
  } = props;
  let sizeClass = "";
  switch (sizes) {
    case "lg":
      sizeClass = "h-5 w-5 text-txt-lg";
      break;
    case "md":
      sizeClass = "h-4 w-4 text-txt-md";
      break;
    case "sm":
      sizeClass = "h-3 w-3  text-txt-sm";
      break;
  }
  return (
    <div className="flex w-full items-start gap-2 text-txt-sm text-tertiary">
      <input
        id={id}
        type="checkbox"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => {
          const value = returnBoolean ? e.target.checked : e.target.value;
          onChange(value);
        }}
        checked={checked}
        value={valueKey}
        className={`defaultCheckbox relative ${sizeClass} appearance-none items-center 
        justify-center rounded-sm border border-gray-300 text-white/0 outline-none transition duration-[0.2s]
        checked:border-none checked:text-white hover:cursor-pointer dark:border-white/10 ${
          color === "red"
            ? "checked:border-none checked:bg-red-500 dark:checked:bg-red-400"
            : color === "blue"
            ? "checked:border-none checked:bg-brand-50 dark:checked:bg-blue-400"
            : color === "green"
            ? "checked:border-none checked:bg-green-500 dark:checked:bg-green-400"
            : color === "yellow"
            ? "checked:border-none checked:bg-yellow-500 dark:checked:bg-yellow-400"
            : color === "orange"
            ? "checked:border-none checked:bg-orange-500 dark:checked:bg-orange-400"
            : color === "teal"
            ? "checked:border-none checked:bg-teal-500 dark:checked:bg-teal-400"
            : color === "navy"
            ? "checked:border-none checked:bg-navy-500 dark:checked:bg-navy-400"
            : color === "lime"
            ? "checked:border-none checked:bg-lime-500 dark:checked:bg-lime-400"
            : color === "cyan"
            ? "checked:border-none checked:bg-cyan-500 dark:checked:bg-cyan-400"
            : color === "pink"
            ? "checked:border-none checked:bg-pink-500 dark:checked:bg-pink-400"
            : color === "purple"
            ? "checked:border-none checked:bg-purple-500 dark:checked:bg-purple-400"
            : color === "amber"
            ? "checked:border-none checked:bg-amber-500 dark:checked:bg-amber-400"
            : color === "indigo"
            ? "checked:border-none checked:bg-indigo-500 dark:checked:bg-indigo-400"
            : color === "gray"
            ? "checked:border-none checked:bg-gray-500 dark:checked:bg-gray-400"
            : "checked:bg-brand-50 dark:checked:bg-brand-400"
        } ${extra}`}
        name="weekly"
        {...rest}
      />
      {labelKey && <span>{labelKey}</span>}
    </div>
  );
});

export default Checkbox;
