export const RATES = {
  RATES: {
    RATE: {
      GET: {
        INDEX: "rates/rate/get",
        ACTIVE: "rates/rate/get/active",
      },
      LIST: "rates/rate/list",
      ADD: "rates/rate/add",
      UPDATE: "rates/rate/update",
    },
    RATE_STRUCTURE: {
      LIST: "rates/rate-structure/list",
      ADD: "rates/rate-structure/add",
    },
  },
};
