import { notifySimple } from "providers/toast";
import { gateway } from "./baseClient";
import { isAxiosError } from "axios";
import type { RequestData, ResponseData } from "../types/api";

export default async function makeApiRequest(
  { url, method, data, params }: RequestData,
  gatewayToUse: any = gateway,
  showToastMessage: boolean = true
): Promise<ResponseData> {
  try {
    const response = await gatewayToUse({
      url,
      method,
      data,
      params,
    });
    return {
      data: response.data,
      status: response.status,
      isLoading: false,
    };
  } catch (error: any) {
    let errorMessage = "Unknown error occurred";
    if (error.response) {
      const { data } = error.response ?? {};
      errorMessage = data?.message ?? data?.detail ?? data;
    } else if (isAxiosError(error)) {
      errorMessage = error.message;
    } else if (error?.message) {
      errorMessage = error.message;
    }
    if (showToastMessage) {
      notifySimple(errorMessage, "error");
    }
    return {
      data: [],
      status: undefined,
      isLoading: false,
      error: errorMessage,
    };
  }
}
