import { useAuth } from "api/hooks/useAuth";
import { ratesStructureList } from "api/rates";
import { siteList } from "api/site";
import { Account } from "api/types/user";
import { userAccounts } from "api/user";
import Default from "layouts/auth/types/Default";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setAccountContext } from "store/account/accountSlice";
import {
  setLoading,
  setRateStructures,
  setSiteList,
  setWelcome,
} from "store/appSlice";
import AuthCard from "widgets/cards/auth";
import GenericButton from "widgets/shared/buttons/generic";

function AccountsDefault() {
  const auth = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { needsAccountSetup } = auth;
  const [accounts, setAccounts] = useState<Account[]>([]);

  const sortedAccounts = [...accounts].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const doUpdateAttributes = async (formData: Account, refData: boolean) => {
    dispatch(setWelcome(true));
    await auth.updateUserAttributes({
      "custom:active_account": formData.id,
    });

    dispatch(setAccountContext(formData));
    localStorage.setItem("accountContext", JSON.stringify(formData));
    dispatch(setWelcome(false));
    if (refData) {
      fetchSitelist();
      fetchRateStructurelist();
    }
  };

  const fetchAccounts = useCallback(async () => {
    dispatch(setLoading(true));
    const { data, status, isLoading, error } = await userAccounts();
    dispatch(setLoading(isLoading));
    if (status === 200) {
      if (data.accounts.length === 1) {
        doUpdateAttributes(data.accounts[0], true);
      } else {
        setAccounts(data.accounts);
      }
    }
  }, []);

  const fetchSitelist = useCallback(async () => {
    dispatch(setSiteList([]));
    const { data, error, isLoading, status } = await siteList();

    if (status === 200) {
      const sortedData = data.sort((a: { name: string }, b: { name: any }) => {
        return a.name.localeCompare(b.name);
      });
      dispatch(setSiteList(sortedData));
      localStorage.setItem("siteList", JSON.stringify(sortedData));
    }
  }, []);

  const fetchRateStructurelist = useCallback(async () => {
    dispatch(setRateStructures([]));
    const { data, error, isLoading, status } = await ratesStructureList();

    if (status === 200) {
      const sortedData = data.sort((a: { name: string }, b: { name: any }) => {
        return a.name.localeCompare(b.name);
      });
      dispatch(setRateStructures(sortedData));
      localStorage.setItem("rateStructureList", JSON.stringify(sortedData));
    }
  }, []);

  useEffect(() => {
    const savedAccountContext = localStorage.getItem("accountContext");
    const savedSiteList = localStorage.getItem("siteList");
    const savedRateStructureList = localStorage.getItem("rateStructureList");

    if (savedAccountContext) {
      dispatch(setAccountContext(JSON.parse(savedAccountContext)));
      doUpdateAttributes(JSON.parse(savedAccountContext), false);
    } else {
      fetchAccounts();
    }

    if (savedSiteList) {
      dispatch(setSiteList(JSON.parse(savedSiteList)));
    }

    if (savedRateStructureList) {
      dispatch(setRateStructures(JSON.parse(savedRateStructureList)));
    }
  }, [needsAccountSetup]);

  return (
    <Default
      maincard={
        <div>
          <AuthCard
            title={t("auth.accounts.title")}
            description={t("auth.accounts.description")}
          >
            <div className="flex flex-wrap gap-2">
              {sortedAccounts.map((acc: Account) => {
                return (
                  <>
                    <GenericButton
                      text={acc.name}
                      onClick={() => doUpdateAttributes(acc, true)}
                      variant="outline"
                      size="md"
                    />
                  </>
                );
              })}
            </div>
          </AuthCard>
        </div>
      }
    />
  );
}

export default AccountsDefault;
