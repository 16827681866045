import { ratesRateList } from "api/rates";
import { Site } from "api/types/site";
import { t } from "i18next";
import LabeledField from "providers/labeledField";
import { useCallback, useState } from "react";
import { MdAdd } from "react-icons/md";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { rateStructuresList } from "store/appSelectors";
import { setLoading, setRateStructures } from "store/appSlice";
import GenericCard from "widgets/cards/generic";
import RateStructureDialog from "widgets/dialogs/rateStructure";
import RateStructureForm from "widgets/forms/rates/ratesStructure";
import CircleButton from "widgets/shared/buttons/circle-button";
import ComboBox from "widgets/shared/inputs/combobox";
import { ratesStructureList } from "api/rates";
import { set } from "zod";

interface GeneralProps {
  site?: Site;
  onUpdate?: (id: string, active: boolean) => void;
}

const RateStructureSetup: React.FC<GeneralProps> = ({ site, onUpdate }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const ratesStructureLists = useSelector(rateStructuresList, shallowEqual);
  const [selectedRateList, setSelectedRateList] = useState();

  const [selectedStructure, setSelectedStructure] = useState(
    ratesStructureLists[0].id || ""
  );
  const [selectedRate, setSelectedRate] = useState("");

  const fetchRateList = async () => {
    const { data, status, isLoading } = await ratesRateList(
      selectedStructure || ""
    );

    if (status && status === 200) {
      setIsActive(true);
      setSelectedRateList(data);
      setSelectedRate(data[0]?.id);
    }
  };

  const fetchRateStructurelist = useCallback(async () => {
    setIsOpen(false);
    setLoading(true);
    const { data, error, isLoading, status } = await ratesStructureList();
    setLoading(isLoading);
    if (status === 200) {
      const sortedData = data.sort((a: { name: string }, b: { name: any }) => {
        return a.name.localeCompare(b.name);
      });
      dispatch(setRateStructures(sortedData));
      localStorage.setItem("rateStructureList", JSON.stringify(sortedData));
    }
  }, []);

  return (
    <>
      <RateStructureDialog
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onSuccess={fetchRateStructurelist}
      />
      {!loading && (
        <>
          <div className="flex justify-end">
            <CircleButton
              label="New"
              icon={MdAdd}
              onClick={() => {
                setIsOpen(true);
              }}
              size="md"
              variant="outline"
            />
          </div>

          <div className="grid w-full grid-cols-2 gap-2">
            <LabeledField
              label={"Rate Structure Id:"}
              id={""}
              className="heading-md"
            >
              <ComboBox
                id={"rate-structure-id-0"}
                items={ratesStructureLists || []}
                sizes="md"
                placeholder={t("form.placeholder.site.billing.period")}
                labelKey="name"
                valueKey="id"
                value={selectedStructure || ""}
                onChange={(val) => {
                  setSelectedStructure(val);
                  fetchRateList();
                }}
                clearIcon={false}
                filter
              />
            </LabeledField>
            <LabeledField label={"Rates Id:"} id={""} className="heading-md">
              <ComboBox
                id={"rate-structure-id-1"}
                items={selectedRateList || []}
                sizes="md"
                placeholder={t("form.placeholder.site.billing.period")}
                labelKey="name"
                valueKey="id"
                value={selectedRate}
                onChange={(val) => {
                  setSelectedRate(val);
                  onUpdate(val, isActive);
                  setIsActive(false);
                }}
                clearIcon={false}
                filter
              />
            </LabeledField>
          </div>
        </>
      )}
    </>
  );
};

export default RateStructureSetup;
