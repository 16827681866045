import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ComboBox from "widgets/shared/inputs/combobox";
import GenericButton from "widgets/shared/buttons/generic";
import Checkbox from "widgets/shared/inputs/checkbox";
import DatePickerField from "widgets/shared/inputs/datePicker";
import { BillingDateDropdown } from "models/dropdowns/billingDate";
import { useEffect } from "react";
// Schema
const RateStructureSchema = z
  .object({
    name: z.string().min(1, { message: i18next.t("zod.error.required") }),
    publicFlag: z.boolean(),
    billingDate: z
      .number()
      .min(1, { message: i18next.t("zod.error.required") }),
    vatInclusive: z.boolean(),
    startDate: z.date({ required_error: i18next.t("zod.error.required") }),
    endDate: z.date().nullable(),
  })
  .refine(
    (data) => {
      if (data.startDate) {
        const oneMonthLater = new Date(data.startDate);
        oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
        return data.endDate >= oneMonthLater;
      }
      return true;
    },
    {
      message: i18next.t("zod.error.endDateAfterStartDate"),
      path: ["endDate"],
    }
  );

export type RateStructureFormType = z.infer<typeof RateStructureSchema>;

// Props
type RateStructureFormProps = {
  submit: (data: RateStructureFormType) => void;
  onClose: () => void;
  defaultValues?: RateStructureFormType;
  values?: RateStructureFormType;
  loading?: boolean;
};

const RateStructureForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
}: RateStructureFormProps) => {
  const form = useForm<RateStructureFormType>({
    resolver: zodResolver(RateStructureSchema),
    defaultValues: {
      name: "",
      publicFlag: false,
      billingDate: 1,
      vatInclusive: false,
      startDate: null,
      endDate: null,
    },
    values,
  });

  const { t } = useTranslation();
  const { errors, isDirty } = form.formState;

  const onSubmit = async (formData: RateStructureFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  const startDate = form.watch("startDate");

  useEffect(() => {
    if (startDate) {
      const endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + 1);
      form.setValue("endDate", endDate);
    }
  }, [startDate, form.setValue]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className=" space-y-6">
        <div className="mt-[12px] grid grid-cols-1 gap-3 ">
          <FormField
            control={form.control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.structure.name")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.device.name")}
                    id="name"
                    type="text"
                    state={errors.name ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="grid grid-cols-1 gap-0 md:grid-cols-2 md:gap-5">
            <FormField
              control={form.control}
              name="publicFlag"
              rules={{ required: false }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel></FormLabel>
                  <FormControl>
                    <Checkbox
                      id="public-flag"
                      color="blue"
                      labelKey="Public"
                      state={errors.publicFlag ? "error" : undefined}
                      onFocus={clearErrors}
                      returnBoolean
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="vatInclusive"
              rules={{ required: false }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel></FormLabel>
                  <FormControl>
                    <Checkbox
                      id="vat-inclusive"
                      color="blue"
                      labelKey="Vat Inclusive"
                      state={errors.vatInclusive ? "error" : undefined}
                      onFocus={clearErrors}
                      returnBoolean
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="billingDate"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.billing.date")}</FormLabel>
                <FormControl>
                  <ComboBox
                    items={BillingDateDropdown}
                    placeholder={t("form.placeholder.role")}
                    id="billing-date"
                    type="number"
                    state={errors.billingDate ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="startDate"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.start.date")}</FormLabel>
                <FormControl>
                  <DatePickerField
                    id="start-date"
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
                <FormDescription>
                  {field.value && (
                    <div className=" text-primary">
                      {new Date(field.value).toLocaleDateString()}
                    </div>
                  )}
                </FormDescription>
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-end py-4">
          <div className="flex gap-2">
            <GenericButton
              onClick={onClose}
              text={t("button.action.cancel")}
              size="md"
              variant="ghost"
            />

            <GenericButton
              type="submit"
              text={t("button.action.ok")}
              disabled={!isDirty}
              loading={loading || false}
              size="md"
            />
          </div>
        </div>
      </form>
    </Form>
  );
};

export default RateStructureForm;
