import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import InputField from "widgets/shared/inputs/inputField";
import { useNavigate } from "react-router-dom";
import GenericButton from "widgets/shared/buttons/generic";

// Schema
const SignInSchema = z.object({
  username: z.string().min(1, {
    message: i18next.t("zod.error.username"),
  }),
  password: z.string().min(1, {
    message: i18next.t("zod.error.password"),
  }),
});

export type SignInFormType = z.infer<typeof SignInSchema>;

// Props
type SignInFormProps = {
  submit: (data: SignInFormType) => void;
};

const SignInForm = ({ submit }: SignInFormProps) => {
  const form = useForm<SignInFormType>({
    resolver: zodResolver(SignInSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { errors } = form.formState;

  const onSubmit = async (formData: SignInFormType) => {
    try {
      await submit(formData);
    } catch (error) {}
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-3">
          <FormField
            control={form.control}
            name="username"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.username")}</FormLabel>
                <FormControl>
                  <InputField
                    variant="auth"
                    placeholder={t("form.placeholder.username")}
                    id="username"
                    type="text"
                    state={errors.username ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.password")}</FormLabel>
                <FormControl>
                  <InputField
                    variant="auth"
                    placeholder={t("form.placeholder.password")}
                    id="password"
                    type="password"
                    state={errors.password ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className=" flex items-center justify-end">
            <GenericButton
              size="xs"
              variant={"link"}
              onClick={() => navigate("/auth/forgot-password/default")}
              text={t("auth.sign_in.action.forgot.password")}
            />
          </div>
        </div>

        <GenericButton
          type="submit"
          text={t("auth.sign_in.action")}
          extra="w-full"
        />
      </form>
    </Form>
  );
};

export default SignInForm;
