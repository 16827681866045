import { useAuth } from "api/hooks/useAuth";
import Default from "layouts/auth/types/Default";
import { useTranslation } from "react-i18next";
import AuthCard from "widgets/cards/auth";
import ConfirmForgotPasswordForm, {
  ConfirmForgotPasswordFormType,
} from "widgets/forms/auth/confirmForgotPassword";

function ConfirmForgotPasswordDefault() {
  const auth = useAuth();
  const { t } = useTranslation();

  // Function for forgot password confirm
  const doConfirm = (formData: ConfirmForgotPasswordFormType) => {
    auth.forgotPasswordSubmit(formData.code, formData.newPassword);
  };

  const doResendOtp = () => {
    auth.forgotPassword("");
  };

  return (
    <Default
      maincard={
        <div>
          <AuthCard
            title={t("auth.confirm.forgot.password.title")}
            description={t("auth.confirm.forgot.password.description")}
            link={t("auth.resend.verification.code")}
            onClick={doResendOtp}
          >
            <ConfirmForgotPasswordForm
              submit={(data) => {
                doConfirm(data);
              }}
            />
          </AuthCard>
        </div>
      }
    />
  );
}

export default ConfirmForgotPasswordDefault;
