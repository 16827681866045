"use client";

import { IconType } from "react-icons";

const CircleButton = ({
  loading = false,
  disabled = false,
  size = "lg",
  variant = "solid",
  type = "button",
  color = "primary",
  label,
  onClick,
  extra,
  icon: Icon,
}: {
  loading?: boolean;
  disabled?: boolean;
  size?: "lg" | "md" | "sm" | "xs";
  variant?: "solid" | "outline" | "ghost";
  type?: "button" | "submit";
  color?: "primary" | "secondary";
  label?: string;
  onClick?: () => void;
  extra?: string;
  icon?: IconType;
}) => {
  let typeClass = "";
  let sizeClass = "";
  let sizeIconClass = "";
  let labelClass = "";
  let colorClass = "";
  let opacityClass = disabled || loading ? "opacity-40" : "opacity-100";

  // Define color class based on variant and color
  switch (color) {
    case "primary":
      switch (variant) {
        case "solid":
          colorClass =
            "text-white dark:text-darkPrimary bg-primary dark:bg-primary/90 hover:opacity-40 focus:opacity-40";
          break;
        case "outline":
          colorClass =
            "text-primary dark:text-primary/90 border border-primary dark:border-primary/90 hover:opacity-40 focus:opacity-40";
          break;
        case "ghost":
          colorClass =
            "text-primary dark:text-primary/90 bg-transparent hover:opacity-40 focus:opacity-40";
          break;
      }
      break;
    case "secondary":
      switch (variant) {
        case "solid":
          colorClass =
            "text-white dark:text-darkSecondary bg-secondary dark:bg-secondary/90 hover:opacity-40 focus:opacity-40";
          break;
        case "outline":
          colorClass =
            "text-secondary dark:text-secondary/90 border border-secondary dark:border-secondary/90 hover:opacity-40 focus:opacity-40";
          break;
        case "ghost":
          colorClass =
            "text-secondary dark:text-secondary/90 bg-transparent hover:opacity-40 focus:opacity-40";
          break;
      }
      break;
  }

  switch (size) {
    case "lg":
      sizeClass = "h-12 heading-md";
      sizeIconClass = "h-10 w-10 p-0";
      labelClass = `${Icon ? "pr-4" : "px-4"}`;
      break;
    case "md":
      sizeClass = "h-10 heading-sm";
      sizeIconClass = "h-8 w-8 p-1";
      labelClass = `${Icon ? "pr-3" : "px-3"}`;

      break;
    case "sm":
      sizeClass = "h-8 heading-sm";
      sizeIconClass = "h-6 w-6";
      labelClass = `${Icon ? "pr-3" : "px-3"}`;

      break;
    case "xs":
      sizeClass = "h-6 heading-xs";
      sizeIconClass = "h-4 w-4";
      labelClass = `${Icon ? "pr-2.5" : "px-2.5"}`;
      break;
  }

  return (
    <button
      // Apply various classes for styling
      className={`linear flex cursor-pointer flex-row items-center rounded-full ${opacityClass} ${sizeClass} ${typeClass} ${colorClass} ${extra}`}
      disabled={loading || disabled}
      onClick={onClick}
      type={type}
    >
      <div className={`flex items-center gap-1 px-1`}>
        {Icon && <Icon className={`${sizeIconClass}`} />}

        {label && <span className={`${labelClass}`}>{label}</span>}
      </div>
    </button>
  );
};

export default CircleButton;
