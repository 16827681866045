import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ComboBox from "widgets/shared/inputs/combobox";
import GenericButton from "widgets/shared/buttons/generic";
import {
  getAvailableRoles,
  getAvailableRolesIncl,
  roles,
} from "models/dropdowns/roles";
import { shallowEqual, useSelector } from "react-redux";
import { getRole } from "store/account/accountSelectors";
import Checkbox from "widgets/shared/inputs/checkbox";
// Schema
const UserEditSchema = z.object({
  // name: z.string().readonly(),
  // family_name: z.string().readonly(),
  // email: z.string().readonly(),
  role: z.string(),
});

export type UserEditFormType = z.infer<typeof UserEditSchema>;

// Props
type UserEditFormProps = {
  submit: (data: UserEditFormType) => void;
  onClose: () => void;
  defaultValues?: UserEditFormType;
  values?: UserEditFormType;
  loading?: boolean;
};

const EditUserForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
}: UserEditFormProps) => {
  const form = useForm<UserEditFormType>({
    resolver: zodResolver(UserEditSchema),
    defaultValues: {
      // name: "",
      // family_name: "",
      // email: "",
      role: "",
    },
    values,
  });

  const { t } = useTranslation();
  const currentRole = useSelector(getRole, shallowEqual);
  const { errors, isDirty } = form.formState;

  const onSubmit = async (formData: UserEditFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className=" space-y-6">
        <div className="mt-[6px] grid grid-cols-1 gap-3 ">
          {/* <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
            <FormField
              control={form.control}
              name="name"
              rules={{ required: false }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("form.input.name")}</FormLabel>
                  <FormControl>
                    <InputField
                      placeholder={t("form.placeholder.name")}
                      id="name"
                      type="text"
                      readonly
                      state={errors.email ? "error" : undefined}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="family_name"
              rules={{ required: false }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("form.input.family.name")}</FormLabel>
                  <FormControl>
                    <InputField
                      placeholder={t("form.placeholder.family.name")}
                      id="family_name"
                      type="text"
                      state={errors.family_name ? "error" : undefined}
                      readOnly
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div> */}
          {/* <FormField
            control={form.control}
            name="email"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.email")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.email")}
                    id="email"
                    type="text"
                    state={errors.email ? "error" : undefined}
                    readOnly
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          /> */}
          <FormField
            control={form.control}
            name="role"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                {/* <FormLabel>{t("form.input.family.name")}</FormLabel> */}
                <FormControl>
                  <div className="grid w-full grid-cols-2 gap-4">
                    {getAvailableRoles(currentRole).map((item, index) => (
                      <Checkbox
                        id={`checkbox-${item.label}`}
                        color="blue"
                        labelKey={item.label}
                        checked={field.value === item.value}
                        valueKey={item.value}
                        onChange={() => field.onChange(item.value)}
                      />
                    ))}
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-grow"></div>
        <div className="flex w-full flex-col pt-2">
          <div className="flex justify-end">
            <GenericButton
              type="submit"
              text={t("generic.action.save.changes")}
              disabled={!isDirty}
              loading={loading || false}
              size="md"
            />
          </div>
        </div>
      </form>
    </Form>
  );
};

export default EditUserForm;
