export const StatusDropdown = [
  {
    label: "VERIFIED",
    value: "VERIFIED",
  },
  {
    label: "UNVERIFIED",
    value: "UNVERIFIED",
  },
  {
    label: "ERROR",
    value: "ERROR",
  },
  {
    label: "QUERIED",
    value: "QUERIED",
  },
];
