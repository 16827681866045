import { Device } from "api/types/device";
import LabeledField from "providers/labeledField";
import water from "assets/img/avatars/avatar7.png";

import PhotoButton from "widgets/shared/buttons/photo";
import GenericCard from "widgets/cards/generic";
import { t } from "i18next";
import { formatDate } from "util/datelib";
import { DeviceConfiguration } from "models/devices/configuration";

interface GeneralProps {
  device?: Device;
  config?: DeviceConfiguration;
}

const Installer: React.FC<GeneralProps> = ({ device, config }) => {
  return (
    <GenericCard
      title={t("device.installer.details")}
      bgColor={`${
        config?.units === "water"
          ? "bg-brand-400"
          : config?.units === "temperature"
          ? "bg-brand-500"
          : "bg-brand-50"
      }`}
      extra="h-full"
      extraTitle="text-secondary heading-md"
    >
      <div className="grid grid-cols-1 gap-1 md:gap-2">
        <LabeledField
          label={`${t("device.installer.name")}: `}
          id={"name"}
          className="text-txt-sm text-secondary"
        >
          <label
            className={`whitespace-normal break-words text-txt-md text-secondary`}
          >
            {device?.installerName}
          </label>
        </LabeledField>
        <LabeledField
          label={`${t("device.installer.date")}: `}
          id={"date"}
          className="text-txt-sm text-secondary"
        >
          <label
            className={`whitespace-normal break-words text-txt-md text-secondary`}
          >
            {formatDate(device?.installDate, "default")}
          </label>
        </LabeledField>
        <LabeledField
          label={`${t("device.installer.location")}: `}
          id={"location"}
          className="text-txt-sm text-secondary"
        >
          <label
            className={`whitespace-normal break-words text-txt-md text-secondary`}
          >
            {device?.installLocation}
          </label>
        </LabeledField>
        <LabeledField
          label={`${t("device.installer.photos")}: `}
          id={"photos"}
          className="text-txt-sm text-secondary"
        >
          <div className="flex items-center justify-evenly">
            {device?.photo1 && (
              <PhotoButton imageSrc={water} imageKey={device?.photo1} />
            )}
            {device?.photo2 && (
              <PhotoButton imageSrc={water} imageKey={device?.photo2} />
            )}
            {device?.photo3 && (
              <PhotoButton imageSrc={water} imageKey={device?.photo3} />
            )}
          </div>
        </LabeledField>
      </div>
    </GenericCard>
  );
};

export default Installer;
