import { deleteSite, siteList, sitePage } from "api/site";
import { SitePageRespData } from "api/types/site";
import { t } from "i18next";
import { ViewPort } from "models/constants/styling";
import defineClTablecolumns, {
  ClassPageTableModel,
} from "models/tables/classPage";
import { notifySimple } from "providers/toast";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRole } from "store/account/accountSelectors";
import { setLoading, setSiteList } from "store/appSlice";
import TableCard from "widgets/cards/table";
import AddSiteDialog from "widgets/dialogs/addSite";
import GenericDialog from "widgets/dialogs/generic";
import GenericButton from "widgets/shared/buttons/generic";
import { SearchInput } from "widgets/shared/inputs/search";
import Table from "widgets/shared/tables/genericTable";

const Sites = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const role = useSelector(getRole, shallowEqual);
  const [dataSource, setDataSource] = useState<SitePageRespData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<ClassPageTableModel>();
  const [filter, setFilter] = useState("");
  const [state, setState] = useState({
    paginationState: { pageIndex: 0, pageSize: 10 },
    sortingState: [{ desc: false, id: "name" }],
    totCount: 0,
    pageCount: 0,
  });

  const handleRowClick = (row: ClassPageTableModel) => {
    navigate(`/admin/devices?filter=${row.id}`);
  };

  const columns = defineClTablecolumns(
    (info) => {
      const row = info.row.original;
      navigate(`/admin/sites/site?id=${row.id}`);
    },
    (info) => {
      const row = info.row.original;
      setSelected(row);
      setIsOpen(true);
    }
  );

  const updatePage = () => {
    setDialog(false);
    setIsOpen(false);
    setFilter("");
    setState((prevState) => ({
      ...prevState,
      paginationState: {
        pageIndex: 0,
        pageSize: 10,
      },
      totCount: 0,
      pageCount: 0,
    }));
    updateSitelist();
  };

  const fetchData = useCallback(async () => {
    const { paginationState, sortingState } = state;
    setIsLoading(true);
    const { data, status, isLoading, error } = await sitePage({
      search: filter,
      pageIndex: paginationState.pageIndex + 1,
      pageSize: paginationState.pageSize,
      sort: sortingState.length > 0 ? sortingState[0].id : "",
      desc: sortingState.length > 0 ? sortingState[0].desc : false,
    });
    setIsLoading(isLoading);
    if (!error) {
      setDataSource(data.classes || []);
      setState((prevState) => ({
        ...prevState,
        paginationState: {
          pageIndex: data.pageIndex - 1,
          pageSize: 10,
        },
        totCount: data.totalCount,
        pageCount: data.totalPages,
      }));
    }
  }, [
    filter,
    state.paginationState.pageIndex,
    state.paginationState.pageSize,
    state.sortingState,
  ]);

  const doDelete = async () => {
    dispatch(setLoading(true));

    const { status } = await deleteSite(selected.id);
    dispatch(setLoading(false));
    if (status === 200) {
      notifySimple("Site deleted", "success");
      updatePage();
    } else {
      notifySimple("Failed to Delete Site", "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateSitelist = useCallback(async () => {
    dispatch(setSiteList([]));
    const { data, error, isLoading, status } = await siteList();

    if (status === 200) {
      const sortedData = data.sort((a: { name: string }, b: { name: any }) => {
        return a.name.localeCompare(b.name);
      });
      dispatch(setSiteList(sortedData));
      localStorage.setItem("siteList", JSON.stringify(sortedData));
    }
  }, []);

  return (
    <div className="mt-6 grid h-full w-full grid-cols-1 gap-y-4">
      {role !== "user" && (
        <AddSiteDialog
          isOpen={dialog}
          onClose={() => setDialog(false)}
          onSuccess={updatePage}
        />
      )}
      <GenericDialog
        isOpen={isOpen}
        title={"Warning"}
        onClose={() => setIsOpen(false)}
        onDelete={doDelete}
      >
        <div>{`Are you sure you want to delete this Site?`}</div>
      </GenericDialog>
      <TableCard
        extra={`pb-0 ${ViewPort.contentFitHeight}`}
        filterbar={
          <div className="flex h-full w-full flex-col gap-y-3">
            <div className="flex justify-end">
              {role !== "user" && (
                <GenericButton
                  onClick={() => {
                    setDialog(true);
                  }}
                  type="button"
                  text="Add Site"
                />
              )}
            </div>

            <SearchInput
              onChange={(filters) => {
                setFilter(filters);
                setState((prevState) => ({
                  ...prevState,
                  paginationState: { pageIndex: 0, pageSize: 10 },
                }));
              }}
              type="text"
              value={filter}
            />
          </div>
        }
      >
        <div className="overflow-auto">
          <Table
            loading={isLoading}
            data={dataSource}
            columns={columns}
            pagination={state.paginationState}
            paginationOptions={{
              onPaginationChange: (pagination) => {
                const page =
                  typeof pagination === "function"
                    ? pagination(state.paginationState)
                    : pagination;
                setState((prevState) => ({
                  ...prevState,
                  paginationState: page,
                }));
              },
              rowCount: state.totCount,
              pageCount: state.pageCount,
            }}
            sorting={state.sortingState}
            onSortingChange={(updaterOrValue) => {
              const newSortingState =
                typeof updaterOrValue === "function"
                  ? updaterOrValue(state.sortingState)
                  : updaterOrValue;
              if (newSortingState && newSortingState.length > 0) {
                setState((prevState) => ({
                  ...prevState,
                  sortingState: newSortingState,
                }));
              } else {
                setState((prevState) => ({
                  ...prevState,
                  sortingState: [{ id: "name", desc: false }],
                }));
              }
            }}
            onRowClick={handleRowClick}
          />
        </div>
      </TableCard>
    </div>
  );
};

export default Sites;
