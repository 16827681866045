import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { auth } from "routes";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";

export default function Auth() {
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`${prop.path}`} element={prop.component} key={key} />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      return null;
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <Routes>
        {getRoutes(auth)}
        <Route
          path="/"
          element={<Navigate to="/auth/sign-in/default" replace />}
        />
      </Routes>
    </div>
  );
}
