import React from "react";
import ReactApexChart from "react-apexcharts";

type ChartProps = {
  // using `interface` is also ok
  [x: string]: any;
};
type ChartState = {
  chartData: any[];
  chartOptions: any;
};

class PieChart extends React.Component<ChartProps, ChartState> {
  constructor(props: {
    chartData: any[];
    chartOptions: any;
    label: any;
    lastComms: string;
  }) {
    super(props);

    this.state = {
      chartData: props.chartData,
      chartOptions: props.chartOptions,
    };
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData,
      chartOptions: this.props.chartOptions,
    });
  }

  componentDidUpdate(prevProps: ChartProps) {
    // Check if chartData or chartOptions have changed, then update the state
    if (
      prevProps.chartData !== this.props.chartData ||
      prevProps.chartOptions !== this.props.chartOptions ||
      prevProps.label !== this.props.label
    ) {
      this.setState({
        chartData: this.props.chartData,
        chartOptions: this.props.chartOptions,
      });
    }
  }

  render() {
    return (
      <div className="flex items-center justify-center">
        <ReactApexChart
          options={this.state.chartOptions}
          series={this.state.chartData}
          type="radialBar"
          width="465"
        />
      </div>
    );
  }
}

export default PieChart;
