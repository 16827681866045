import profile from "assets/img/crm/vbz.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { t } from "i18next";
import { shallowEqual, useSelector } from "react-redux";
import { getRole } from "store/account/accountSelectors";
import { userFullname } from "store/user/userSelectors";

const Banner = () => {
  const fullname = useSelector(userFullname, shallowEqual);
  const role = useSelector(getRole, shallowEqual);

  return (
    <Card extra={"items-center p-4 bg-cover bg-brand-50 h-full"}>
      {/* background and profile */}
      <div
        className="relative flex h-32 w-full justify-center rounded-2xl bg-cover bg-center"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full">
          <img
            className="h-full w-full rounded-full border-4 border-white"
            src={profile}
            alt=""
          />
        </div>
      </div>
      {/* name and position */}
      <div className="mt-14 flex flex-col items-center">
        <h4 className="heading-lg text-secondary">{fullname}</h4>
        <div className="my-2 flex items-center justify-center">
          <div className="text-txt-md text-secondary">
            {t("banner.account.type")}
          </div>
          <div className="mx-2 text-txt-md text-accent">
            {role.toUpperCase()}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Banner;
