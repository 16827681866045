import { deviceSet } from "api/device";
import { Device, DeviceSetBody } from "api/types/device";
import { t } from "i18next";
import { DeviceConfiguration } from "models/devices/configuration";
import { useState } from "react";
import {
  MdEditDocument,
  MdFlashOn,
  MdThermostat,
  MdWaterDrop,
} from "react-icons/md";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import DeviceGeneralForm, {
  DeviceGeneralFormType,
} from "widgets/forms/devices/general";
import QrCodeButton from "widgets/shared/buttons/qrcode";

interface GeneralProps {
  device?: Device;
  config?: DeviceConfiguration;
  onUpdate?: () => void;
}

const General: React.FC<GeneralProps> = ({ device, config, onUpdate }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  const doDeviceSet = async (formData: DeviceGeneralFormType) => {
    const body: DeviceSetBody = {
      snr: device?.snr,
      meterNumber: formData.meterNumber,
      meterModel: formData.meterModel,
      address: formData.address,
      unitNumber: formData.unitNumber,
    };

    dispatch(setLoading(true));
    const { status, isLoading } = await deviceSet(body);
    dispatch(setLoading(isLoading));

    if (status && status === 200) {
      setEdit(false);
      onUpdate();
    }
  };

  return (
    <div
      className={`!z-5 relative flex h-full w-full flex-col rounded-[20px] ${
        config?.units === "water"
          ? "bg-brand-400"
          : config?.units === "temperature"
          ? "bg-brand-500"
          : "bg-brand-50"
      } p-4 md:p-6`}
    >
      <div className="mb-4 flex w-full gap-2">
        {config.units === "water" && (
          <MdWaterDrop className="heading-3xl rounded-xl bg-white text-primary" />
        )}
        {config.units === "electricity" && (
          <MdFlashOn className="heading-3xl rounded-xl bg-white text-primary" />
        )}
        {config.units === "temperature" && (
          <MdThermostat className="heading-3xl rounded-xl bg-white text-primary" />
        )}
        <div className="flex w-full flex-col justify-between md:flex-row md:items-start">
          <div className="flex flex-col">
            <h4
              className={`heading-lg ${
                config?.units === "water" ? "text-primary" : "text-secondary"
              }`}
            >{`${device?.name}`}</h4>
            <p className={`mt-0 text-txt-md text-secondary`}>{`${t(
              "generic.serial"
            )}: ${device?.snr}`}</p>
          </div>

          <div
            className={`mt-4 flex gap-2 md:ml-4 md:mt-0 ${
              config?.units === "water" ? "text-primary" : "text-secondary"
            }`}
          >
            <MdEditDocument
              className="heading-lg hover:cursor-pointer"
              onClick={() => {
                setEdit(!edit);
              }}
            />
            <QrCodeButton
              data={device?.snr}
              class={`${
                config?.units === "water" ? "text-primary" : "text-secondary"
              }`}
            />
          </div>
        </div>
      </div>
      <DeviceGeneralForm
        values={{
          meterNumber: device?.meterNumber,
          meterModel: device?.meterModel,
          address: device?.address,
          unitNumber: device?.unitNumber,
        }}
        submit={doDeviceSet}
        onClose={() => {}}
        disabled={!edit}
      />
    </div>
  );
};

export default General;
