import { useAuth } from "api/hooks/useAuth";
import Default from "layouts/auth/types/Default";
import { useTranslation } from "react-i18next";
import AuthCard from "widgets/cards/auth";
import VerificationForm, {
  VerificationFormType,
} from "widgets/forms/auth/verification";

function VerificationDefault() {
  const auth = useAuth();
  const { t } = useTranslation();

  // Function for signing up user
  const doVerification = (formData: VerificationFormType) => {
    auth.confirmSignUp(formData.code);
  };

  const doResendOtp = () => {
    auth.resendSignUp("");
  };

  return (
    <Default
      maincard={
        <div className="-mt-10">
          <AuthCard
            title={t("auth.verification.title")}
            description={t("auth.verification.description")}
            link={t("auth.resend.verification.code")}
            onClick={doResendOtp}
          >
            <VerificationForm
              submit={(data) => {
                doVerification(data);
              }}
            />
          </AuthCard>
        </div>
      }
    />
  );
}

export default VerificationDefault;
