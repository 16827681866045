import { RawReqBody } from "api/types/report";
import { useEffect, useState } from "react";
import RawReport from "reportDesigner/types/raw";
import { formatDateToZ } from "util/datelib";
import GenericCard from "widgets/cards/generic";

interface MyDocumentProps {
  data: any;
}

const Viewer: React.FC<MyDocumentProps> = ({ data }) => {
  const [body, setBody] = useState<any>(data);

  const handleBody = () => {
    if (data.reportType === 1) {
      const rawBody: RawReqBody = {
        siteId: data.site,
        snr: data.device,
        from: formatDateToZ(data.dateRange[0]),
        to: formatDateToZ(data.dateRange[1], true),
      };

      setBody(rawBody);
    }
  };

  useEffect(() => {
    if (data) {
      handleBody();
    }
  }, [data]);

  return (
    <div className={`flex h-full`}>
      <GenericCard bgColor="bg-white">
        <RawReport body={body} />
      </GenericCard>
    </div>
  );
};

export default Viewer;
