import { t } from "i18next";
import * as React from "react";
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  useFormContext,
} from "react-hook-form";

// Define Form component as FormProvider
const Form = FormProvider;

// Define context value type for form fields
type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  name: TName;
  required?: any;
};

// Create context for form field
const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue
);

// Define FormField component
const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    // Provide field context value to children components
    <FormFieldContext.Provider
      value={{ name: props.name, required: props.rules?.required }}
    >
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

// Custom hook to access form field context
const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    required: fieldContext.required,
    ...fieldState,
  };
};

// Define context value type for form item
type FormItemContextValue = {
  id: string;
};

// Create context for form item
const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue
);

// Define FormItem component
const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const id = React.useId();

  return (
    // Provide item context value to children components
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={className} {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = "FormItem";

// Define FormLabel component
const FormLabel = React.forwardRef<
  HTMLLabelElement,
  React.LabelHTMLAttributes<HTMLLabelElement>
>(({ className, ...props }, ref) => {
  const { error, formItemId, required } = useFormField();

  return (
    // Render label with appropriate styles and accessibility attributes
    <label
      ref={ref}
      className={`text-txt-md     ${className || "text-tertiary"}`}
      htmlFor={formItemId}
      {...props}
    >
      {props.children} {required && <span className="text-tertiary">*</span>}
    </label>
  );
});
FormLabel.displayName = "FormLabel";

// Define FormControl component
const FormControl = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } =
    useFormField();

  return (
    // Render form control with appropriate accessibility attributes
    <div
      className="py-1"
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = "FormControl";

// Define FormDescription component
const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={`text-txt-sm text-secondary dark:text-darkSecondary`}
      {...props}
    />
  );
});
FormDescription.displayName = "FormDescription";

// Define FormMessage component
const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    // Render form message with appropriate styles and accessibility attributes
    <p
      ref={ref}
      id={formMessageId}
      className={`text-txt-sm text-tertiary dark:text-darkTertiary`}
      {...props}
    >
      {t(`${body}`)}*
    </p>
  );
});
FormMessage.displayName = "FormMessage";

// Export components and hooks
export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
};
