import makeApiRequest from "./clients/apiRequest";
import { gateway } from "./clients/baseClient";
import { versionApiPath } from "./common";
import { ENDPOINTS } from "./endpoints";
import { ResponseData } from "./types/api";

export async function getSignedUrl(photoKey: string): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.ADMIN.PHOTO.GENERATE_SIGNED_URL}`,
    body: { photoKey: photoKey },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function uvsUploadXlsx(file: any): Promise<ResponseData> {
  file.append("endpoint", `${versionApiPath}/${ENDPOINTS.ADMIN.UVS.UPLOAD}`);

  return await makeApiRequest(
    {
      url: `/uploads`,
      method: "post",
      data: file,
    },
    gateway,
    true
  );
}

export async function uvsDisbursements(
  startDate: string,
  endDate: string
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.ADMIN.UVS.DISBURSEMENTS}`,
    body: {
      startDate: startDate,
      endDate: endDate,
    },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}
