import Default from "layouts/auth/types/Default";

import AuthCard from "widgets/cards/auth";
import { useTranslation } from "react-i18next";
import SignInForm, { SignInFormType } from "widgets/forms/auth/signIn";
import { useAuth } from "api/hooks/useAuth";
import { useNavigate } from "react-router-dom";

function SignInDefault() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Function for signing in user
  const doSignIn = (formData: SignInFormType) => {
    auth.signIn(formData.username, formData.password);
  };

  return (
    <Default
      maincard={
        <div>
          <AuthCard
            title={t("auth.sign_in.title")}
            description={t("auth.sign_in.description")}
            footer={t("auth.sign_in.footer")}
            link={t("auth.sign_in.footer.link")}
            onClick={() => navigate("/auth/sign-up/default")}
          >
            <SignInForm
              submit={(data) => {
                doSignIn(data);
              }}
            />
          </AuthCard>
        </div>
      }
    />
  );
}

export default SignInDefault;
