import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import GenericCard from "widgets/cards/generic";
import WaterRateForm from "widgets/forms/sites/rates/water";
import SewerageRateForm from "widgets/forms/sites/rates/sewerage";
import ElectricityRateForm from "widgets/forms/sites/rates/electricity";
import LabeledField from "providers/labeledField";
import { setLoading } from "store/appSlice";
import { ratesRateGet, ratesRateUpdate } from "api/rates";
import GenericButton from "widgets/shared/buttons/generic";
import { RateResponse, RatesUpdateBody } from "api/types/rates";
import OtherRateForm from "widgets/forms/sites/rates/other";
import { set } from "zod";

interface GeneralProps {
  rateId?: string;
  active?: boolean;
  onUpdate?: () => void;
}

const RatesSetup: React.FC<GeneralProps> = ({ rateId, active, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [rates, setRates] = useState<RateResponse>(null);

  const [updatedRates, setUpdatedRates] = useState<RatesUpdateBody>(null);

  const fetchRate = async () => {
    setLoading(true);
    const { data, status, isLoading } = await ratesRateGet(rateId || "");
    setLoading(isLoading);
    if (status && status === 200) {
      console.log(data);
      setRates({ ...data });
    }
  };

  useEffect(() => {
    if (rateId) {
      setRates(null);
      fetchRate();
    }
  }, [rateId]);

  const handleUpdate = (formData: any, type: string) => {
    setDirty(true);
    if (type === "water") {
      setUpdatedRates((prev) => ({
        ...prev,
        rateId: rateId,
        water: formData,
      }));
    } else if (type === "sewerage") {
      setUpdatedRates((prev) => ({
        ...prev,
        rateId: rateId,
        sewerage: formData,
      }));
    } else if (type === "electricity") {
      setUpdatedRates((prev) => ({
        ...prev,
        rateId: rateId,
        electricity: formData,
      }));
    } else if (type === "other") {
      setUpdatedRates((prev) => ({
        ...prev,
        rateId: rateId,
        other: formData.other,
      }));
    }
  };

  const updateRate = async () => {
    setLoading(true);
    const { data, status, isLoading } = await ratesRateUpdate(updatedRates);
    setLoading(isLoading);
    if (status && status === 200) {
      setUpdatedRates(null);
      fetchRate();
      setDirty(false);
    }
  };

  const handleDiscard = () => {
    setDirty(false);
    setUpdatedRates(null);
    const oldRates = { ...rates };

    // Set rates to null temporarily
    setRates(null);

    // Restore old rates after a short delay
    setTimeout(() => {
      setRates(oldRates);
    }, 200); // Adjust the delay as necessary (200 ms in this example)
  };

  return (
    <>
      {!loading && (
        <>
          <div className={` flex h-full w-full flex-col gap-6 overflow-x-auto`}>
            <div>
              {dirty ? (
                <div className="mt-12 flex justify-end gap-2">
                  <GenericButton
                    text={"Store"}
                    size="md"
                    onClick={updateRate}
                  />
                  <GenericButton
                    text={"Discard"}
                    size="md"
                    variant="outline"
                    onClick={handleDiscard}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {rates ? ( // Only render forms if rates are loaded
              <>
                {" "}
                <LabeledField
                  label={"Water Rates"}
                  id={""}
                  className="heading-lg"
                >
                  <WaterRateForm
                    key={`${rateId}-water`}
                    submit={(form) => handleUpdate(form, "water")}
                    onClose={() => {}}
                    values={rates?.water}
                  />
                </LabeledField>
                <LabeledField
                  label={"Sewerage Rates"}
                  id={""}
                  className="heading-lg"
                >
                  <SewerageRateForm
                    key={`${rateId}-sewerage`}
                    submit={(form) => handleUpdate(form, "sewerage")}
                    onClose={() => {}}
                    values={rates?.sewerage}
                  />
                </LabeledField>
                <LabeledField
                  label={"Electricity Rates"}
                  id={""}
                  className="heading-lg"
                >
                  <ElectricityRateForm
                    key={`${rateId}-electricity`}
                    submit={(form) => handleUpdate(form, "electricity")}
                    onClose={() => {}}
                    values={rates?.electricity}
                  />
                </LabeledField>
                <LabeledField
                  label={"Other Rates"}
                  id={""}
                  className="heading-lg"
                >
                  <OtherRateForm
                    key={`${rateId}-other`}
                    submit={(form) => handleUpdate(form, "other")}
                    onClose={() => {}}
                    values={{ other: rates?.other }}
                  />
                </LabeledField>
              </>
            ) : (
              <p>Loading...</p> // Loading indicator while rates are being fetched
            )}
          </div>
        </>
      )}
    </>
  );
};

export default RatesSetup;
