import { deviceDelete, devicePage } from "api/device";
import { DeviceDeleteBody, DevicePageRespData } from "api/types/device";
import { t } from "i18next";
import { ViewPort } from "models/constants/styling";
import { deviceConfiguration } from "models/devices/configuration";
import { InstalledDropdown } from "models/dropdowns/installed";
import { ModelFilterDropdown } from "models/dropdowns/modelFilter";
import { StatusDropdown } from "models/dropdowns/status";
import defineDpTablecolumns, {
  DevicePageTableModel,
} from "models/tables/devicePage";
import { notifySimple } from "providers/toast";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getRole } from "store/account/accountSelectors";
import { accountSiteList } from "store/appSelectors";
import { setLoading } from "store/appSlice";
import TableCard from "widgets/cards/table";
import AddDeviceDialog from "widgets/dialogs/addDevice";
import DataQualityDialog from "widgets/dialogs/dataQuality";
import GenericDialog from "widgets/dialogs/generic";
import GenericButton from "widgets/shared/buttons/generic";
import Combobox from "widgets/shared/inputs/combobox";
import { SearchInput } from "widgets/shared/inputs/search";
import Table from "widgets/shared/tables/genericTable";

const Devices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = defineDpTablecolumns(
    (info) => {
      const row = info.row.original;
      handleRowClick(row);
    },
    (info) => {
      const row = info.row.original;
      setSelected(row);
      setIsOpen0(true);
    },
    (info) => {
      const row = info.row.original;
      setSelected(row);
      setIsOpen(true);
    }
  );
  const userInfo = useSelector(accountSiteList, shallowEqual);
  const role = useSelector(getRole, shallowEqual);

  const [searchParams] = useSearchParams();

  // Extract the filter parameter
  const site = searchParams.get("filter");

  const [dataSource, setDataSource] = useState<DevicePageRespData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen0, setIsOpen0] = useState(false);
  const [selected, setSelected] = useState<DevicePageTableModel>();
  const [filter, setFilter] = useState();
  const [state, setState] = useState({
    paginationState: { pageIndex: 0, pageSize: 10 },
    sortingState: [{ desc: true, id: "name" }],
    totCount: 0,
    pageCount: 0,
    classId: "",
    model: [],
    status: undefined,
    installed: undefined,
  });

  const updatePage = () => {
    setDialog(false);
    setIsOpen(false);
    setIsOpen0(false);
    fetchData();
  };

  const fetchData = useCallback(async () => {
    const { paginationState, sortingState, classId, model, status, installed } =
      state;
    setIsLoading(true);
    const { data, error, isLoading } = await devicePage({
      search: filter,
      pageIndex: paginationState.pageIndex + 1,
      pageSize: paginationState.pageSize,
      sort: sortingState[0].id,
      desc: sortingState[0].desc,
      classId: classId,
      models: model,
      status: status,
      installed: installed,
    });
    setIsLoading(isLoading);
    if (!error) {
      setDataSource(data.devices || []);
      setState((prevState) => ({
        ...prevState,
        paginationState: { pageIndex: data.currentPage - 1, pageSize: 10 },
        totCount: data.totalCount,
        pageCount: data.totalPages,
      }));
    }
  }, [
    filter,
    state.paginationState.pageIndex,
    state.paginationState.pageSize,
    state.sortingState,
    state.classId,
    state.model,
    state.status,
    state.installed,
  ]);

  const doDelete = async () => {
    dispatch(setLoading(true));

    const snr: DeviceDeleteBody = {
      snr: selected.snr,
    };
    const { status } = await deviceDelete(snr);
    dispatch(setLoading(false));
    if (status === 200) {
      notifySimple("Device deleted", "success");
      updatePage();
    } else {
      notifySimple("Failed to Delete Device", "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRowClick = (row: DevicePageTableModel) => {
    if (deviceConfiguration.canDeviceRoute(row.model)) {
      navigate(`/admin/devices/device?snr=${row.snr}`);
    } else {
      notifySimple(t("deviceNotSupported"), "error");
    }
  };

  return (
    <div className="z-10 mt-6 grid h-full w-full grid-cols-1 gap-y-4">
      {role !== "user" && (
        <AddDeviceDialog
          isOpen={dialog}
          onClose={() => setDialog(false)}
          onSuccess={updatePage}
        />
      )}
      <GenericDialog
        isOpen={isOpen}
        title={"Warning"}
        onClose={() => setIsOpen(false)}
        onDelete={doDelete}
      >
        <div>{`Are you sure you want to delete this device? SNR: ${selected?.snr}`}</div>
      </GenericDialog>
      <DataQualityDialog
        isOpen={isOpen0}
        device={selected}
        onClose={() => setIsOpen0(false)}
        onSuccess={updatePage}
      />
      <TableCard
        extra={`pb-0 ${ViewPort.contentFitHeight} overflow-auto`}
        filterbar={
          <div className="flex h-full w-full flex-col gap-y-3">
            <div className="flex justify-end">
              {role !== "user" && (
                <GenericButton
                  onClick={() => {
                    setDialog(true);
                  }}
                  type="button"
                  text="Add Device"
                />
              )}
            </div>
            <div className="flex flex-col gap-4 lg:flex-row">
              <div className="flex-1 lg:flex-grow-[1]">
                <Combobox
                  id="device-select-model"
                  placeholder="Select group"
                  variant="filter"
                  value={""}
                  onChange={(item) => {
                    setState((prevState) => ({
                      ...prevState,
                      model: item,
                      paginationState: { pageIndex: 0, pageSize: 10 },
                    }));
                  }}
                  items={ModelFilterDropdown}
                  labelKey="label"
                  valueKey="value"
                />
              </div>
              <div className="flex-1 lg:flex-grow-[1]">
                <Combobox
                  filter
                  id="device-select-site"
                  placeholder="Select site"
                  variant="filter"
                  value={site || ""}
                  onChange={(item) => {
                    setState((prevState) => ({
                      ...prevState,
                      classId: item,
                      paginationState: { pageIndex: 0, pageSize: 10 },
                    }));
                  }}
                  items={userInfo}
                  labelKey="name"
                  valueKey="id"
                />
              </div>
              <div className="flex-1 lg:flex-grow-[1]">
                <Combobox
                  id="device-select-status"
                  placeholder="Select status"
                  variant="filter"
                  value={undefined}
                  onChange={(item) => {
                    setState((prevState) => ({
                      ...prevState,
                      status: item,
                      paginationState: { pageIndex: 0, pageSize: 10 },
                    }));
                  }}
                  items={StatusDropdown}
                  labelKey="label"
                  valueKey="value"
                />
              </div>
              <div className="flex-1 lg:flex-grow-[1]">
                <Combobox
                  id="device-select-installed"
                  placeholder="Select installed"
                  variant="filter"
                  value={undefined}
                  onChange={(item) => {
                    setState((prevState) => ({
                      ...prevState,
                      installed: item,
                      paginationState: { pageIndex: 0, pageSize: 10 },
                    }));
                  }}
                  items={InstalledDropdown}
                  labelKey="label"
                  valueKey="value"
                />
              </div>
              <div className="flex-1 lg:flex-grow-[1]">
                <SearchInput
                  onChange={(filters) => {
                    setFilter(filters);
                    setState((prevState) => ({
                      ...prevState,
                      paginationState: { pageIndex: 0, pageSize: 10 },
                    }));
                  }}
                  type="text"
                  value={filter}
                />
              </div>
            </div>
          </div>
        }
      >
        <div className="">
          <Table
            loading={isLoading}
            onRowClick={handleRowClick}
            data={dataSource}
            columns={columns}
            pagination={state.paginationState}
            paginationOptions={{
              onPaginationChange: (pagination) => {
                const page =
                  typeof pagination === "function"
                    ? pagination(state.paginationState)
                    : pagination;
                setState((prevState) => ({
                  ...prevState,
                  paginationState: page,
                }));
              },
              rowCount: state.totCount,
              pageCount: state.pageCount,
            }}
            sorting={state.sortingState}
            onSortingChange={(updaterOrValue) => {
              const newSortingState =
                typeof updaterOrValue === "function"
                  ? updaterOrValue(state.sortingState)
                  : updaterOrValue;
              if (newSortingState && newSortingState.length > 0) {
                setState((prevState) => ({
                  ...prevState,
                  sortingState: newSortingState,
                }));
              } else {
                setState((prevState) => ({
                  ...prevState,
                  sortingState: [{ id: "name", desc: true }],
                }));
              }
            }}
          />
        </div>
      </TableCard>
    </div>
  );
};

export default Devices;
