import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/modal";
import LabeledField from "providers/labeledField";
import { notifySimple } from "providers/toast";
import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import GenericButton from "widgets/shared/buttons/generic";
import PinInputs from "widgets/shared/inputs/pinInput";

const MfaDialog = (props: {
  isOpen: boolean;
  onClose: () => void;
  onOk: (val: string) => void;
  code: string;
  secret: string;
}) => {
  const { t } = useTranslation();
  const [code, setCode] = useState("");

  // Copy secret
  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.secret).then(
      () => {
        notifySimple("Copied to Clipboard", "success");
      },
      (err) => {
        notifySimple("Failed to copy", "error");
      }
    );
  };

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent
          className="top-[6vh] mx-2 rounded-md bg-white p-4 sm:top-[12vh] sm:!m-auto sm:!max-w-[60%] md:!max-w-[50%] md:p-6 lg:!max-w-[45%] xl:!max-w-[35%] 2xl:!max-w-[25%]"
          style={{
            maxHeight: "calc(100vh - 100px)",
            overflowY: "auto",
          }}
        >
          <ModalHeader className="heading-lg  justify-between gap-6 pb-2 text-primary dark:text-darkPrimary">
            {t("profile.mfa.dialog.title")}
            <ModalCloseButton className="absolute right-4 top-4 z-10 text-txt-xs text-primary dark:text-darkPrimary" />
          </ModalHeader>

          <ModalBody className="py-2 text-txt-md text-tertiary">
            <div className="flex flex-col gap-4">
              <div className="text-wrap text-txt-md text-primary dark:text-darkPrimary">
                {t("profile.mfa.dialog.step.one")}
              </div>

              <div className="flex flex-col items-center">
                <div className="">
                  <QRCode
                    size={256}
                    style={{ maxWidth: "100%", height: "auto", width: "auto" }}
                    value={props.code}
                    viewBox={`0 0 256 256`}
                  />
                </div>
                <div className="w-3/4 break-words p-3 text-center text-txt-sm text-primary dark:text-darkPrimary">
                  {props.secret}
                </div>
                <GenericButton
                  text={t("button.action.copy.secret.key")}
                  size="md"
                  onClick={copyToClipboard}
                />
              </div>

              <LabeledField label={t("profile.mfa.dialog.step.two")} id={"pin"}>
                <PinInputs
                  value={undefined}
                  onChange={(val: SetStateAction<string>) => {
                    setCode(val);
                  }}
                />
              </LabeledField>
            </div>
          </ModalBody>
          <ModalFooter className="gap-2 pt-4">
            {props.onClose && (
              <GenericButton
                onClick={props.onClose}
                text={t("button.action.close")}
                size="md"
                variant="ghost"
              />
            )}
            {props.onOk && (
              <GenericButton
                onClick={() => props.onOk(code)}
                text={t("button.action.ok")}
                size="md"
              />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MfaDialog;
