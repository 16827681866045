import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import GenericButton from "widgets/shared/buttons/generic";
import { useEffect } from "react";
// Schema
const DeviceGeneralSchema = z.object({
  meterNumber: z.string(),
  meterModel: z.string(),
  address: z.string(),
  unitNumber: z.string(),
});

export type DeviceGeneralFormType = z.infer<typeof DeviceGeneralSchema>;

// Props
type DeviceGeneralFormProps = {
  submit: (data: DeviceGeneralFormType) => void;
  onClose: () => void;
  defaultValues?: DeviceGeneralFormType;
  values?: DeviceGeneralFormType;
  loading?: boolean;
  disabled?: boolean;
};

const DeviceGeneralForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
  disabled,
}: DeviceGeneralFormProps) => {
  const form = useForm<DeviceGeneralFormType>({
    resolver: zodResolver(DeviceGeneralSchema),
    defaultValues: {
      meterNumber: "",
      meterModel: "",
      address: "",
      unitNumber: "",
    },
    values,
  });

  const { t } = useTranslation();
  const { errors, isDirty } = form.formState;

  const clearErrors = () => {
    form.clearErrors();
  };

  // Automatically submit the form if valid
  const onSubmit = async (formData: DeviceGeneralFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    if (disabled && isDirty) {
      form.reset(values || defaultValues);
    }
  }, [disabled, isDirty, form, values, defaultValues]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className=" space-y-2">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-5">
          <FormField
            control={form.control}
            name="meterNumber"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">
                  {t("form.input.meter.number")}
                </FormLabel>
                <FormControl>
                  <InputField
                    sizes={"md"}
                    placeholder={t("form.placeholder.meter.number")}
                    id="meterNumber"
                    type="text"
                    state={errors.meterNumber ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled}
                    {...field}
                    extra="bg-secondary"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="meterModel"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">
                  {t("form.input.meter.model")}
                </FormLabel>
                <FormControl>
                  <InputField
                    sizes={"md"}
                    placeholder={t("form.placeholder.meter.model")}
                    id="meterModel"
                    type="text"
                    state={errors.meterModel ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled}
                    {...field}
                    extra="bg-secondary"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-5">
          <FormField
            control={form.control}
            name="address"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">
                  {t("form.input.address")}
                </FormLabel>
                <FormControl>
                  <InputField
                    sizes={"md"}
                    placeholder={t("form.placeholder.address")}
                    id="address"
                    type="text"
                    state={errors.address ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled}
                    {...field}
                    extra="bg-secondary"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="unitNumber"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">
                  {t("form.input.unit.number")}
                </FormLabel>
                <FormControl>
                  <InputField
                    sizes={"md"}
                    placeholder={t("form.placeholder.unit.number")}
                    id="unitNumber"
                    type="text"
                    state={errors.unitNumber ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled}
                    {...field}
                    extra="bg-secondary"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        {isDirty && !disabled && (
          <div className="flex w-full flex-col pt-4">
            <div className="flex justify-end">
              <GenericButton
                text={t("button.action.save.changes")}
                disabled={!isDirty}
                loading={loading || false}
                type="submit"
              />
            </div>
          </div>
        )}
      </form>
    </Form>
  );
};

export default DeviceGeneralForm;
