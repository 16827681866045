import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../../../providers/form";
import { useTranslation } from "react-i18next";
import PinInputs from "widgets/shared/inputs/pinInput";
import GenericButton from "widgets/shared/buttons/generic";

// Schema
const VerificationSchema = z.object({
  code: z.string().min(6),
});

export type VerificationFormType = z.infer<typeof VerificationSchema>;

// Props
type VerificationFormProps = {
  submit: (data: VerificationFormType) => void;
};

const VerificationForm = ({ submit }: VerificationFormProps) => {
  const form = useForm<VerificationFormType>({
    resolver: zodResolver(VerificationSchema),
    defaultValues: {
      code: "",
    },
  });

  const { t } = useTranslation();

  const onSubmit = async (formData: VerificationFormType) => {
    try {
      await submit(formData);
    } catch (error) {}
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-4">
          <FormField
            control={form.control}
            name="code"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.code")}</FormLabel>
                <FormControl>
                  <PinInputs {...field} />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <GenericButton
          type="submit"
          text={t("auth.verification.action")}
          extra="w-full"
        />
      </form>
    </Form>
  );
};

export default VerificationForm;
