import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import GenericButton from "widgets/shared/buttons/generic";
// Schema
const SiteSchema = z.object({
  name: z.string().min(1, { message: i18next.t("zod.error.required") }),
  reference: z.string().min(1, { message: i18next.t("zod.error.required") }),
});

export type SiteFormType = z.infer<typeof SiteSchema>;

// Props
type SiteFormProps = {
  submit: (data: SiteFormType) => void;
  onClose: () => void;
  defaultValues?: SiteFormType;
  values?: SiteFormType;
  loading?: boolean;
};

const SiteForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
}: SiteFormProps) => {
  const form = useForm<SiteFormType>({
    resolver: zodResolver(SiteSchema),
    defaultValues: {
      name: "",
      reference: "",
    },
    values,
  });

  const { t } = useTranslation();
  const { errors, isDirty } = form.formState;

  const onSubmit = async (formData: SiteFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className=" space-y-6">
        <div className="mt-[12px] grid grid-cols-1 gap-3 ">
          <FormField
            control={form.control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.site.name")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.site.name")}
                    id="name"
                    type="text"
                    state={errors.name ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="reference"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.site.reference")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.site.reference")}
                    id="reference"
                    type="text"
                    state={errors.reference ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-end py-4">
          <div className="flex gap-2">
            <GenericButton
              onClick={onClose}
              text={t("button.action.cancel")}
              size="md"
              variant="ghost"
            />

            <GenericButton
              type="submit"
              text={t("button.action.ok")}
              disabled={!isDirty}
              loading={loading || false}
              size="md"
            />
          </div>
        </div>
      </form>
    </Form>
  );
};

export default SiteForm;
