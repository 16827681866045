// Props
type TableCardProps = {
  extra?: string;
  children?: React.ReactNode;
  topRight?: React.ReactNode;
  filterbar?: React.ReactNode;
};

const TableCard = ({
  extra,
  children,
  filterbar,
  topRight,
}: TableCardProps) => {
  return (
    <div
      className={`!z-5 relative flex w-full flex-col rounded-[20px] bg-white p-4 dark:!bg-navy-800 dark:text-white md:px-8 md:pt-6 ${extra}`}
    >
      <div className="w-full">
        <div className="flex gap-2">{filterbar}</div>
        <div className="flex-grow">{children}</div>
      </div>
    </div>
  );
};

export default TableCard;
