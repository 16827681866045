import { Navigate } from "react-router-dom";
import { useAuth } from "./api/hooks/useAuth";

type Props = {
  children?: React.ReactNode;
};

const PrivateRoute: React.FC<Props> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  // Not signed in go to Sign in page
  if (!isAuthenticated) {
    return <Navigate to="/auth/sign-in/default" />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
