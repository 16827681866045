import GenericCard from "widgets/cards/generic";
import SetupForm from "widgets/forms/reports/setup";

interface MyDocumentProps {
  onData: (data: any) => void;
}

const Configuration: React.FC<MyDocumentProps> = ({ onData }) => {
  return (
    <div className={`flex h-full`}>
      <GenericCard
        title="Settings"
        description="Configure your settings here"
        bgColor="bg-white"
      >
        <SetupForm
          submit={(data) => {
            onData(data);
          }}
        />
      </GenericCard>
    </div>
  );
};

export default Configuration;
