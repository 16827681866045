import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import GenericButton from "widgets/shared/buttons/generic";
import ComboBox from "widgets/shared/inputs/combobox";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { accountSiteList } from "store/appSelectors";
import { useEffect, useState } from "react";
import MultiCombobox from "widgets/shared/inputs/multiselectCombobox";
import DateRangePickerField from "widgets/shared/inputs/dateRangePicker";
import { DeviceListReqBody } from "api/types/device";
import { deviceList } from "api/device";
import { setLoading } from "store/appSlice";
import { ReportConfiguration } from "models/reports/configuration";

// Schema
const SetupSchema = z
  .object({
    reportType: z.number().min(1, { message: i18next.t("zod.error.required") }),
    site: z.string().min(1, { message: i18next.t("zod.error.required") }),
    device: z.string().optional(),
    devices: z.array(z.string()).optional(),
    dateRange: z
      .tuple([z.date(), z.date()])
      .refine((dates) => dates[0] <= dates[1], {
        message: i18next.t("zod.error.dateRangeInvalid"),
      }),
  })
  .refine(
    (data) => {
      if (data.reportType === 2) {
        return data.devices && data.devices.length > 0;
      }
      if (data.reportType === 1) {
        return data.device && data.device.length > 0;
      }
      return true;
    },
    {
      message: i18next.t("zod.error.required"),
      path: ["device", "devices"],
    }
  );

export type SetupFormType = z.infer<typeof SetupSchema>;

// Props
type SetupFormProps = {
  submit: (data: SetupFormType) => void;
};

const SetupForm = ({ submit }: SetupFormProps) => {
  const form = useForm<SetupFormType>({
    resolver: zodResolver(SetupSchema),
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const siteList = useSelector(accountSiteList, shallowEqual);
  const [devList, setDevList] = useState([]);
  const { errors } = form.formState;

  const [selectedReportConfig, setSelectedReportConfig] = useState(null);

  const onSubmit = async (formData: SetupFormType) => {
    try {
      await submit(formData);
    } catch (error) {}
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  const fetchDeviceList = async (siteId: string) => {
    if (!siteId) return;
    const body: DeviceListReqBody = {
      classId: siteId,
      sort: "snr",
      desc: true,
    };
    dispatch(setLoading(true));
    const { data, isLoading } = await deviceList(body);
    dispatch(setLoading(isLoading));

    if (data) {
      setDevList(data);
    }
  };

  useEffect(() => {
    // Clear device selection when reportType changes
    form.setValue("device", "");
    form.setValue("devices", []);
  }, [selectedReportConfig]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="mt-6 space-y-6">
        <div className="space-y-3">
          <FormField
            control={form.control}
            name="reportType"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.select.report.type")}</FormLabel>
                <FormControl>
                  <ComboBox
                    items={ReportConfiguration}
                    placeholder={t("form.placeholder.select.report.type")}
                    id="report-type"
                    labelKey="name"
                    valueKey="id"
                    state={errors.reportType ? "error" : undefined}
                    onFocus={clearErrors}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                      const config = ReportConfiguration.find(
                        (item) => item.id === value
                      );
                      console.log(config);
                      setSelectedReportConfig(config);
                    }}
                    // {...field}
                    clearIcon={false}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="site"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.select.site")}</FormLabel>
                <FormControl>
                  <ComboBox
                    items={siteList}
                    placeholder={t("form.placeholder.select.site")}
                    id="site"
                    labelKey="name"
                    valueKey="id"
                    value={field.value}
                    state={errors.site ? "error" : undefined}
                    onFocus={clearErrors}
                    onChange={(value) => {
                      field.onChange(value);
                      fetchDeviceList(value);
                    }}
                    clearIcon={false}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {selectedReportConfig?.multiSelect && devList.length > 0 && (
            <FormField
              control={form.control}
              name="devices"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("form.input.select.devices")}</FormLabel>
                  <FormControl>
                    <MultiCombobox
                      items={devList}
                      placeholder={t("form.placeholder.select.devices")}
                      id="devices"
                      labelKey="name"
                      valueKey="snr"
                      value={field.value}
                      state={errors.devices ? "error" : undefined}
                      onFocus={clearErrors}
                      onChange={(value) => field.onChange(value)}
                      clearIcon={false}
                      selectAll
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {selectedReportConfig?.singleSelect && devList.length > 0 && (
            <FormField
              control={form.control}
              name="device"
              rules={{ required: true }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("form.input.select.device")}</FormLabel>
                  <FormControl>
                    <ComboBox
                      items={devList}
                      placeholder={t("form.placeholder.select.device")}
                      id="device"
                      labelKey="name"
                      valueKey="snr"
                      value={field.value}
                      state={errors.device ? "error" : undefined}
                      onFocus={clearErrors}
                      {...field}
                      clearIcon={false}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          {form.watch("site") && selectedReportConfig?.dateRange && (
            <FormField
              control={form.control}
              name="dateRange"
              rules={{ required: true }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("form.input.select.date.range")}</FormLabel>
                  <FormControl>
                    <DateRangePickerField
                      id="date-range"
                      onFocus={clearErrors}
                      sizes="md"
                      value={field.value as [Date, Date]} // Ensure the value is cast to the correct type
                      onChange={(value) => {
                        field.onChange(value); // value should be of type LooseValue
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                  <FormDescription>
                    {field.value && Array.isArray(field.value) && (
                      <div className="text-primary">
                        {new Date(field.value[0]).toLocaleDateString()} -{" "}
                        {new Date(field.value[1]).toLocaleDateString()}
                      </div>
                    )}
                  </FormDescription>
                </FormItem>
              )}
            />
          )}
        </div>
        <div className="flex-grow"></div>
        <GenericButton type="submit" text={"Generate"} extra="w-full" />
      </form>
    </Form>
  );
};

export default SetupForm;
