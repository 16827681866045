import React, { useEffect } from "react";
import { setLoading } from "store/appSlice";
import { reportRaw } from "api/report";
import { useDispatch } from "react-redux";
import { ReportResponse } from "api/types/report";
import ReportViewer from "reportDesigner/viewer";

interface MyDocumentProps {
  body: any;
}

const RawReport: React.FC<MyDocumentProps> = ({ body }) => {
  // Dynamically retrieve headers from the first item
  const [reportData, setReportData] = React.useState<ReportResponse>();

  const dispatch = useDispatch();
  const handleGenerateReport = async (formData: any) => {
    dispatch(setLoading(true));
    const { data, isLoading } = await reportRaw(formData);
    dispatch(setLoading(isLoading));

    if (data) {
      // console.log("Report generated", data);
      setReportData(data);
    }
  };

  useEffect(() => {
    if (body) {
      handleGenerateReport(body);
    }
  }, [body]);

  return (
    <>
      <ReportViewer report={reportData} />
    </>
  );
};

export default RawReport;
