export const SITE = {
  SITE: {
    PAGE: "site/page",
    LIST: "site/list",
    CREATE: "site/create",
    UPDATE: "site/update",
    DELETE: "site/delete",
    GET: "site/get",
  },
};
