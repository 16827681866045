import { useAuth } from "api/hooks/useAuth";
import { deleteAccount } from "api/user";
import { t } from "i18next";
import { notifySimple } from "providers/toast";
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { userInformation } from "store/user/userSelectors";
import ProfileCard from "widgets/cards/profile";
import GenericDialog from "widgets/dialogs/generic";
import GenericButton from "widgets/shared/buttons/generic";

const Delete = () => {
  const auth = useAuth();
  const userInfo = useSelector(userInformation, shallowEqual);

  const [open, setOpen] = useState(false);

  const toggleDialog = () => {
    setOpen(!open);
  };

  const doDelete = async () => {
    setOpen(!open);

    const { status } = await deleteAccount(userInfo.email);

    if (status && status === 200) {
      auth.deleteUser();
    } else {
      notifySimple("Failed to delete Account", "error");
    }
  };

  return (
    <>
      <ProfileCard
        title={t("profile.delete.account.title")}
        description={t("profile.delete.account.description")}
        bgColor="bg-brand-400"
        textColor="text-secondary"
        extra="h-full"
      >
        <div className="flex h-full w-full flex-col">
          <div className="flex-grow"></div>
          <div className="flex justify-end">
            <GenericButton
              variant={"outline"}
              size="md"
              text={t("button.action.delete")}
              onClick={toggleDialog}
              extra="bg-transparent"
            />
          </div>
        </div>
      </ProfileCard>

      <GenericDialog
        isOpen={open}
        title={t("dialog.warning.title")}
        onClose={toggleDialog}
        onDelete={doDelete}
      >
        <div>{t("profile.delete.account.warning")}</div>
      </GenericDialog>
    </>
  );
};

export default Delete;
