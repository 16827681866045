import { useAuth } from "api/hooks/useAuth";
import Default from "layouts/auth/types/Default";
import { useTranslation } from "react-i18next";
import AuthCard from "widgets/cards/auth";
import LockForm, { LockFormType } from "widgets/forms/auth/lock";

function LockDefault() {
  const auth = useAuth();
  const { t } = useTranslation();

  // Function for signing in user MFA
  const doUnlock = (formData: LockFormType) => {
    auth.confirmSignIn(formData.code);
  };

  return (
    <Default
      maincard={
        <div>
          <AuthCard
            title={t("auth.lock.title")}
            description={t("auth.lock.description")}
          >
            <LockForm
              submit={(data) => {
                doUnlock(data);
              }}
            />
          </AuthCard>
        </div>
      }
    />
  );
}

export default LockDefault;
