import { useAuth } from "api/hooks/useAuth";
import Default from "layouts/auth/types/Default";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AuthCard from "widgets/cards/auth";
import ForgotPasswordForm, {
  ForgotPasswordFormType,
} from "widgets/forms/auth/forgotPassword";

function ForgotPasswordDefault() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Function for forgot password
  const doSendLink = (formData: ForgotPasswordFormType) => {
    auth.forgotPassword(formData.email);
  };

  return (
    <Default
      maincard={
        <div>
          <AuthCard
            title={t("auth.forgot.password.title")}
            description={t("auth.forgot.password.description")}
            link={t("auth.forgot.password.link")}
            onClick={() => navigate("/auth/sign-in/default")}
          >
            <ForgotPasswordForm
              submit={(data) => {
                doSendLink(data);
              }}
            />
          </AuthCard>
        </div>
      }
    />
  );
}

export default ForgotPasswordDefault;
