export const ServiceLevelDropdown = [
  {
    label: "HTTP",
    value: "http",
  },
  {
    label: "SFTP",
    value: "sftp",
  },
  {
    label: "Self Managed",
    value: "self-managed",
  },
  {
    label: "Atom Reporting",
    value: "atom-reporting",
  },
  {
    label: "Managed Service",
    value: "managed-service",
  },
];
