import { gateway } from "./clients/baseClient";
import { ENDPOINTS } from "./endpoints/index";
import { ResponseData } from "./types/api";
import makeApiRequest from "./clients/apiRequest";
import { versionApiPath } from "./common";
import {
  SiteCreateBody,
  SiteListReqData,
  SitePageReqBody,
  SitePageReqData,
  SiteUpdateBody,
} from "./types/site";

export async function sitePage(body: SitePageReqBody): Promise<ResponseData> {
  const data: SitePageReqData = {
    endpoint: `${versionApiPath}/${ENDPOINTS.SITE.PAGE}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    false
  );
}

export async function siteList(): Promise<ResponseData> {
  const data: SiteListReqData = {
    endpoint: `${versionApiPath}/${ENDPOINTS.SITE.LIST}`,
    body: {},
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function createSite(body: SiteCreateBody): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.SITE.CREATE}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function updateSite(body: SiteUpdateBody): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.SITE.UPDATE}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function deleteSite(id: string): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.SITE.DELETE}`,
    body: { id: id },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function getSite(id: string): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.SITE.GET}`,
    body: { id: id },
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}
