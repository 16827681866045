import { shallowEqual, useSelector } from "react-redux";
import BounceLoader from "react-spinners/ClipLoader";
import { welcome } from "store/appSelectors";

export const Welcome = (props: {}) => {
  const loading = useSelector(welcome, shallowEqual);

  if (!loading) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-95">
      <div className="flex flex-col items-center justify-center gap-2">
        <BounceLoader loading={loading} color="var(--color-50)" />
        <label>Main loading</label>
      </div>
    </div>
  );
};
