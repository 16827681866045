import { InputHTMLAttributes, forwardRef } from "react";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  extra?: string;
  extraContainer?: string;
  variant?: string;
  state?: "error" | "success"; // Specific states for styling
  centered?: boolean;
  dense?: boolean;
  readonly?: boolean;
  sizes?: "lg" | "md" | "sm" | "xs";
  onChange?: (val: any) => void;
}

// Custom components
const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref) => {
    const {
      value,
      id,
      extra,
      extraContainer,
      sizes = "lg",
      type = "text",
      placeholder,
      variant,
      state,
      dense,
      disabled,
      readonly,
      centered,
      onChange,
      onBlur,
      onFocus,
      ...rest
    } = props;
    let sizeClass = "";

    switch (sizes) {
      case "lg":
        sizeClass = "h-12 px-6 text-txt-lg";
        break;
      case "md":
        sizeClass = "h-10 px-4 text-txt-md";
        break;
      case "sm":
        sizeClass = "h-8 px-3  text-txt-sm";
        break;
      case "xs":
        sizeClass = "h-6 px-2  text-txt-xs";
        break;
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;

      // If input type is number, parse to a number
      const parsedValue =
        type === "number" && !isNaN(Number(inputValue))
          ? parseFloat(inputValue)
          : inputValue;

      // Call the onChange callback with the parsed value instead of the raw value
      onChange(parsedValue);
    };

    return (
      <div className={`${extraContainer} flex w-full`}>
        <input
          autoComplete="off"
          onChange={handleChange}
          onFocus={onFocus}
          disabled={disabled}
          readOnly={readonly}
          type={type}
          id={id}
          placeholder={readonly ? "" : placeholder}
          value={value !== undefined ? String(value) : ""}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onBlur={onBlur}
          className={`flex ${sizeClass} w-full items-center justify-center rounded-2xl border border-brand-700
          ${
            disabled === true
              ? "border-gray-200 bg-gray-200 bg-opacity-40 text-primary"
              : state === "error"
              ? "border-red-500 text-red-500 outline-none placeholder:text-red-500 dark:!border-red-500 dark:!text-red-500 dark:placeholder:!text-red-500"
              : state === "success"
              ? "border-green-500 text-green-500 outline-none placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "bg-transparent text-primary outline-none hover:bg-white focus:border-brand-50 focus:bg-white dark:text-darkPrimary"
          }
          ${extra}`}
        />
      </div>
    );
  }
);

export default InputField;
