import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export class UserContextData {
  jwtAccessToken: string;
  jwtRefreshToken: string;
  cognito_username: string;
  email: string;
  name: string;
  family_name: string;
  phone_number: string;
  mfa_enabled: boolean;
}

// Define the default UserContextData
const initialState: UserContextData = {
  jwtAccessToken: "",
  jwtRefreshToken: "",
  cognito_username: "",
  email: "",
  name: "",
  family_name: "",
  phone_number: "",
  mfa_enabled: false,
};

// This slice contains data related to the state of the user
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserContext: (state: any, action: PayloadAction<UserContextData>) => {
      return action.payload;
    },
    setMfaEnabled: (state: any, action: PayloadAction<boolean>) => {
      state.mfa_enabled = action.payload;
    },
    clearUserContext: (state) => {
      state.jwtAccessToken = "";
      state.jwtRefreshToken = "";
      state.cognito_username = "";
      state.email = "";
      state.name = "";
      state.family_name = "";
      state.phone_number = "";
      state.mfa_enabled = false;
    },
  },
});

export const { setUserContext, clearUserContext, setMfaEnabled } =
  userSlice.actions;
export default userSlice.reducer;
