import * as React from "react";
import { InputHTMLAttributes, forwardRef, useEffect, useState } from "react";
import {
  MdClear,
  MdOutlineArrowCircleDown,
  MdOutlineArrowCircleUp,
} from "react-icons/md";
import DropdownButton from "../buttons/dropdown";
import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/popover";
import { useDisclosure } from "@chakra-ui/hooks";
import { SearchInput } from "./search";
import { t } from "i18next";
import { PortalManager } from "@chakra-ui/portal";

type ValueSet = {
  [key: string]: any;
};

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  value: any;
  onChange: (values: any) => void;
  debounce?: number;
  items: ValueSet[];
  placeholder: string;
  labelKey?: string;
  valueKey?: string;
  filter?: boolean;
  state?: "error" | "success"; // Specific states for styling
  onFocus?: () => void;
  variant?: "filter" | "input";
  placement?: "top" | "bottom";
  clearIcon?: boolean;
  sizes?: "lg" | "md" | "sm" | "xs";
  minWidth?: string; // Add minWidth prop
}

const ComboBox = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const {
    value,
    id,
    debounce = 500,
    labelKey = "label",
    valueKey = "value",
    variant = "input",
    placement = "bottom",
    sizes = "lg",
    clearIcon = true,
    filter = false,
    items,
    placeholder,
    disabled,
    readOnly,
    onChange,
    onFocus,
    state,
    minWidth = "200px",
  } = props;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [values, setValue] = React.useState<any>(undefined);

  const [filterTerm, setFilterTerm] = useState("");

  const filteredItems = items.filter((item) =>
    item[labelKey].toLowerCase().includes(filterTerm.toLowerCase())
  );

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(values);
      setFilterTerm("");
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  let sizeClass = "";

  switch (sizes) {
    case "lg":
      sizeClass = "h-12 px-6 text-txt-lg";
      break;
    case "md":
      sizeClass = "h-10 px-4 text-txt-md";
      break;
    case "sm":
      sizeClass = "h-8 px-4  text-txt-sm";
      break;
    case "xs":
      sizeClass = "h-6 px-2  text-txt-xs";
      break;
  }

  return (
    <div className={`flex ${minWidth}`}>
      <PortalManager zIndex={5000}>
        <Popover
          isOpen={isOpen && !disabled && items.length > 0}
          onOpen={() => {
            onOpen();
          }}
          onClose={onClose}
          closeOnBlur={true}
          matchWidth
          placement={placement}
        >
          <PopoverTrigger>
            <button
              type="button"
              id={id}
              onFocus={onFocus}
              disabled={disabled || readOnly}
              className={`flex ${sizeClass} w-full items-center rounded-2xl ${
                variant === "filter"
                  ? "border-none "
                  : "border border-brand-700 "
              } 
            ${
              disabled === true
                ? "border-gray-200 bg-gray-200 bg-opacity-40"
                : state === "error"
                ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-500 dark:!text-red-500 dark:placeholder:!text-red-500"
                : state === "success"
                ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                : `outline-none ${
                    variant === "filter"
                      ? "bg-gray-50"
                      : "bg-transparent hover:bg-white focus:border-brand-50 focus:bg-white"
                  } `
            }
            `}
            >
              <div className="flex w-full items-center justify-between gap-2 sm:gap-4">
                <div className="flex items-center gap-2 truncate">
                  {values ? (
                    <span className=" truncate text-txt-lg text-primary">
                      {
                        filteredItems.find(
                          (item: any) => item[valueKey] === values
                        )?.[labelKey]
                      }
                    </span>
                  ) : (
                    <span className="truncate text-gray-500">
                      {placeholder}
                    </span>
                  )}
                </div>
                <div className=" flex items-center gap-2">
                  {values && clearIcon && (
                    <MdClear
                      onClick={(e) => {
                        e.stopPropagation();

                        setValue(undefined);
                        onChange(undefined);
                      }}
                      className="cursor-pointer text-primary"
                    />
                  )}
                  {isOpen ? (
                    <MdOutlineArrowCircleUp className="text-primary dark:text-darkPrimary" />
                  ) : (
                    <MdOutlineArrowCircleDown className="text-primary dark:text-darkPrimary" />
                  )}
                </div>
              </div>
            </button>
          </PopoverTrigger>
          {items && items.length > 0 && (
            <PopoverContent
              zIndex={6500}
              bg="white"
              className="`w-max max-h-60 rounded-xl px-2 py-2 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none"
            >
              <>
                {filter && (
                  <div className="px-2 pb-2">
                    <SearchInput
                      onChange={(filters) => setFilterTerm(filters)}
                      type="text"
                      placeholder={t("generic.action.search") + "..."}
                      value={filterTerm}
                    />
                  </div>
                )}
                <div className="overflow-y-scroll">
                  {filteredItems.map((item: any, index: any) => (
                    <DropdownButton
                      key={index}
                      text={item[labelKey]}
                      onClick={() => {
                        setValue(item[valueKey]);
                        onClose();
                      }}
                    />
                  ))}
                </div>
              </>
            </PopoverContent>
          )}
        </Popover>
      </PortalManager>
    </div>
  );
});

export default ComboBox;
