import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../providers/form";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import InputField from "widgets/shared/inputs/inputField";
import SubmitButton from "widgets/shared/buttons/submit";
import { PhoneNumberUtil } from "google-libphonenumber";
import PhoneNumber from "widgets/shared/inputs/phoneNumber";
import Checkbox from "widgets/shared/inputs/checkbox";
import ComboBox from "widgets/shared/inputs/combobox";
import PinInputs from "widgets/shared/inputs/pinInput";
import MultiCombobox from "widgets/shared/inputs/multiselectCombobox";

const phoneUtil = PhoneNumberUtil.getInstance();

// Schema
const ExampleSchema = z.object({
  label: z.string().min(1, {
    message: "Label required",
  }),
  email: z
    .string()
    .min(1, { message: i18next.t("zod.error.required") })
    .email({ message: i18next.t("zod.error.email") }),
  phone_number: z
    .string()
    .min(5, {
      message: i18next.t("zod.error.phone.number"),
    })
    .refine(
      (phone_number) => {
        try {
          return phoneUtil.isValidNumber(
            phoneUtil.parseAndKeepRawInput(phone_number)
          );
        } catch (error) {
          return false;
        }
      },
      {
        message: i18next.t("zod.error.phone.valid"),
      }
    ),
  password: z
    .string()
    .min(8, {
      message: i18next.t("zod.error.password"),
    })
    .refine(
      (password) => {
        // Check if newPassword contains at least one number and one special character
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialCharacter =
          /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);
        return hasNumber && hasSpecialCharacter;
      },
      {
        message: i18next.t("zod.error.password.match"),
      }
    ),
  consent: z
    .boolean({
      message: i18next.t("zod.error.consent"),
    })
    .refine((val) => val === true, {
      message: i18next.t("zod.error.consent"),
    }),
  combobox: z.string().min(1, {
    message: i18next.t("zod.error.required"),
  }),
  multicombobox: z.array(z.string()).min(1, {
    message: i18next.t("zod.error.required"),
  }),
  code: z.string().min(6),
});

export type ExampleFormType = z.infer<typeof ExampleSchema>;

// Props
type ExampleFormProps = {
  submit: (data: ExampleFormType) => void;
};

const ExampleForm = ({ submit }: ExampleFormProps) => {
  const form = useForm<ExampleFormType>({
    resolver: zodResolver(ExampleSchema),
    defaultValues: {
      label: "",
      password: "",
      email: "",
      phone_number: "",
      consent: false,
      combobox: "",
      multicombobox: [],
      code: "",
    },
  });

  const { t } = useTranslation();
  const { errors } = form.formState;

  const onSubmit = async (formData: ExampleFormType) => {
    try {
      await submit(formData);
    } catch (error) {}
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-4">
          <FormField
            control={form.control}
            name="label"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{"Label"}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={"label"}
                    id="label"
                    type="text"
                    state={errors.label ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.password")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.password")}
                    id="password"
                    type="password"
                    state={errors.password ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.email")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.email")}
                    id="email"
                    type="text"
                    state={errors.email ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone_number"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.phone.number")}</FormLabel>
                <FormControl>
                  <PhoneNumber
                    id="phone_number"
                    placeholder={t("form.placeholder.phone.number")}
                    state={errors.phone_number ? "error" : undefined}
                    onFocus={clearErrors}
                    value={field.value}
                    onChang={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="combobox"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.name")}</FormLabel>
                <FormControl>
                  <ComboBox
                    items={[
                      {
                        value: "super",
                        label: "Super",
                      },
                      {
                        value: "administrator",
                        label: "Administrator",
                      },
                      {
                        value: "owner",
                        label: "Owner",
                      },
                      {
                        value: "manager",
                        label: "Manager",
                      },
                      {
                        value: "user",
                        label: "User",
                      },
                    ]}
                    placeholder={"select one..."}
                    id="combobox"
                    state={errors.combobox ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="multicombobox"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{"Select Multiple"}</FormLabel>
                <FormControl>
                  <MultiCombobox
                    items={[
                      {
                        value: "super",
                        label: "Super",
                      },
                      {
                        value: "administrator",
                        label: "Administrator",
                      },
                      {
                        value: "owner",
                        label: "Owner",
                      },
                      {
                        value: "manager",
                        label: "Manager",
                      },
                      {
                        value: "user",
                        label: "User",
                      },
                    ]}
                    placeholder={"select multiple..."}
                    id="multicombobox"
                    type="text"
                    //   state={errors.email ? "error" : undefined}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="code"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.code")}</FormLabel>
                <FormControl>
                  <PinInputs {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="consent"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel></FormLabel>
                <FormControl>
                  <div className="flex">
                    <div className="flex items-center justify-center gap-2">
                      <Checkbox id="consent" {...field} onFocus={clearErrors} />
                      <p
                        id="consent_label"
                        className={`text-text-secondary dark:text-text-darkSecondary text-sm`}
                      >
                        By creating an account means you agree to the Terms and
                        Conditions, and our Privacy Policy
                      </p>
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <SubmitButton variant={"default"} text={"Submit"} />
      </form>
    </Form>
  );
};

export default ExampleForm;
