import { InputHTMLAttributes, forwardRef } from "react";
import { PhoneInput } from "react-international-phone";
import { CountrySelectorStyleProps } from "react-international-phone/dist/components/CountrySelector/CountrySelector";
// import "react-international-phone/style.css";

interface PhoneNumberProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  state?: "error" | "success";
  centered?: boolean;
  dense?: boolean;
  value: string;
  onChang: (value: string) => void;
}

// Custom components
const PhoneNumber = forwardRef<HTMLInputElement, PhoneNumberProps>(
  (props, ref) => {
    const {
      id,
      placeholder,
      disabled,
      required,
      state,
      centered,
      dense,
      onBlur,
      onFocus,
      value,
      onChang,
      ...rest
    } = props;

    // Define custom styles for country selector
    const countrySelectorStyleProps: CountrySelectorStyleProps = {
      className: "h-12",
      buttonClassName: "w-8 m-2",
      buttonContentWrapperClassName: "flex",
      flagClassName: "",
      dropdownArrowClassName: "",
      dropdownStyleProps: {
        className:
          "rounded-xl outline-0 bg-white max-h-48 overflow-y-auto p-4 flex relative top-0 left-0",
        listItemClassName: "bg-white flex w-full gap-4 cursor-pointer",
        listItemFlagClassName: "bg-white w-6 m-1",
        listItemCountryNameClassName: "bg-white flex-grow m-1 text-nowrap",
        listItemDialCodeClassName: "bg-white m-1",
        preferredListDividerClassName: "",
      },
    };

    return (
      <div>
        <PhoneInput
          className={`flex px-4 ${
            dense ? "h-10" : "h-12"
          }  w-full items-center  justify-center rounded-2xl border border-brand-700 px-4 outline-none  ${
            disabled === true
              ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
              : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-500 dark:!text-red-500 dark:placeholder:!text-red-500"
              : state === "success"
              ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "text-primary outline-none hover:bg-white focus:border-brand-50 focus:bg-white"
          }`}
          inputClassName={`flex text-txt-lg text-primary h-10 outline-none bg-transparent ${
            centered ? "text-center" : "w-full"
          } `}
          // inputStyle={{
          //   lineHeight: "3.688rem",
          //   // letterSpacing: "-0.02em",
          //   fontWeight: "400",
          //   // fontFamily: "Lato, sans-serif",
          //   color: "#1d4a64",
          //   backgroundColor: "transparent", // Custom background color
          //   // border: "none",
          //   height: 44,
          // }}
          countrySelectorStyleProps={countrySelectorStyleProps}
          defaultCountry="za"
          forceDialCode
          value={value}
          placeholder={placeholder}
          required={required}
          onChange={onChang}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    );
  }
);

export default PhoneNumber;
