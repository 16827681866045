import ProfileCard from "widgets/cards/profile";
import SetUp from "./SetUp";
import { shallowEqual, useSelector } from "react-redux";
import { userMfaEnabled } from "store/user/userSelectors";
import Badge from "widgets/shared/badge";
import { t } from "i18next";

const TwoFactor = () => {
  const mfaEnabled = useSelector(userMfaEnabled, shallowEqual);

  return (
    <ProfileCard
      title={t("profile.mfa.title")}
      description={t("profile.mfa.description")}
      bgColor="bg-brand-200"
      extra="h-full"
    >
      <div className="flex h-full w-full flex-col">
        <div className="flex">
          <Badge
            variant="outline"
            label={mfaEnabled ? t("generic.enabled") : t("generic.disabled")}
            color={mfaEnabled ? "green" : "red"}
          />
        </div>
        <div className="flex-grow"></div>
        <SetUp
          name={t("profile.mfa.authenticator.app")}
          value={
            mfaEnabled
              ? t("profile.mfa.configured")
              : t("profile.mfa.not.configured")
          }
          actionName={
            mfaEnabled ? t("button.action.disable") : t("button.action.setup")
          }
        />
      </div>
    </ProfileCard>
  );
};

export default TwoFactor;
