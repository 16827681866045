import { useAuth } from "api/hooks/useAuth";
import Default from "layouts/auth/types/Default";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AuthCard from "widgets/cards/auth";
import SignUpForm, { SignUpFormType } from "widgets/forms/auth/signUp";

function SignUpDefault() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Function for signing up user
  const doSignUp = (formData: SignUpFormType) => {
    console.log(formData);
    auth.signUp(formData);
  };

  return (
    <Default
      maincard={
        <div className="mt-6 md:mt-0">
          <AuthCard
            title={t("auth.sign_up.title")}
            description={t("auth.sign_up.description")}
            footer={t("auth.sign_up.footer")}
            link={t("auth.sign_up.footer.link")}
            onClick={() => navigate("/auth/sign-in/default")}
          >
            <SignUpForm
              submit={(data) => {
                doSignUp(data);
              }}
            />
          </AuthCard>
        </div>
      }
    />
  );
}

export default SignUpDefault;
