import ProfileCard from "widgets/cards/profile";
import EditUserForm, { UserEditFormType } from "widgets/forms/users/edit";

// Define the props for the Role component
interface RoleProps {
  role?: string;
  onSubmit?: (formData: UserEditFormType) => void;
}

const Role: React.FC<RoleProps> = ({ role = "", onSubmit }) => {
  const handleSubmit = (formData: UserEditFormType) => {
    console.log(formData);
    if (onSubmit) {
      onSubmit(formData);
    }
  };
  return (
    <>
      <ProfileCard
        title="User Role"
        description="Define User Role"
        bgColor="bg-brand-200"
        extra="h-full"
      >
        <EditUserForm
          values={{ role: role }}
          submit={handleSubmit}
          onClose={() => {}}
        />
      </ProfileCard>
    </>
  );
};

export default Role;
