import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "./providers/localization";
import store from "./store/store";
import { Provider } from "react-redux";

import App from "./App";
import { Spinner } from "providers/spinner";
import { ProvideAuth } from "api/hooks/useAuth";
import { Welcome } from "providers/welcomeLoader";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Spinner />
      <Welcome />
      <ProvideAuth>
        <App />
      </ProvideAuth>
    </Provider>
  </BrowserRouter>
);
