import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export class AccountContextData {
  name: string;
  id: string;
  reference: string;
  created: string;
  role: string;
  classes: string[];
}

// Define the default
const initialState: AccountContextData = {
  name: "",
  id: "",
  reference: "",
  created: "",
  role: "",
  classes: [],
};

// This slice contains data related to the state
const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccountContext: (
      state: any,
      action: PayloadAction<AccountContextData>
    ) => {
      return action.payload;
    },
    clearAccountContext: (state) => {
      state.name = "";
      state.id = "";
      state.reference = "";
      state.created = "";
      state.role = "";
      state.classes = [];
    },
  },
});

export const { setAccountContext, clearAccountContext } = accountSlice.actions;
export default accountSlice.reducer;
