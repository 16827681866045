import { useDispatch } from "react-redux";
import GenericCard from "widgets/cards/generic";
import RatesSetup from "./components/ratesSetup";
import RateStructureSetup from "./components/rateStructureSetup";
import { useState } from "react";

const Rates = () => {
  const dispatch = useDispatch();
  const [rateId, setRateId] = useState("");
  const [active, setActive] = useState(false);

  return (
    <GenericCard extra="h-full" bgColor="bg-white" title="Rates Structure">
      <div className={`-mt-12 grid h-full grid-cols-1 gap-12`}>
        <RateStructureSetup
          onUpdate={(val, active) => {
            setRateId(val);
            setActive(active);
          }}
        />
        <RatesSetup rateId={rateId} active={active} />
      </div>
    </GenericCard>
  );
};

export default Rates;
