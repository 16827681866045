import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/modal";
import { UserLinkReqBody } from "api/types/user";
import { userLink } from "api/user";
import { notifySimple } from "providers/toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import UserForm, { UserFormType } from "widgets/forms/users/user";

const AddUserDialog = (props: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Function for linking an user
  const doLinkUser = async (formData: UserFormType) => {
    dispatch(setLoading(true));
    const newUser: UserLinkReqBody = {
      email: formData.email,
      role: formData.role,
      classes: formData.classes,
    };
    const { status } = await userLink(newUser);
    dispatch(setLoading(false));
    if (status === 200) {
      props.onSuccess();
      notifySimple("User Linked", "success");
    } else if (status === 401) {
      notifySimple("User doesn't exist", "error");
    } else {
      notifySimple("User Linked Failed", "error");
    }
  };

  return (
    <>
      <Modal isCentered isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[22vh] !m-auto !w-max min-w-96 !max-w-[40%] rounded-md bg-white md:top-[12vh]">
          <ModalHeader className="heading-md px-6 py-4 text-primary dark:text-darkPrimary">
            {t("dialog.add.user.title")}
          </ModalHeader>
          <ModalCloseButton className="absolute right-[20px] top-[20px] z-10 text-primary dark:text-darkPrimary" />
          <ModalBody className="px-6 py-2 text-txt-md text-tertiary">
            <UserForm
              submit={(data) => {
                doLinkUser(data);
              }}
              onClose={props.onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddUserDialog;
