import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { PortalManager } from "@chakra-ui/portal";

import { useState } from "react";
import { MdQrCode } from "react-icons/md";
import QRCode from "react-qr-code";

const QrCodeButton = (props: { data: string; class: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [code, setCode] = useState<string | null>(null);

  const handleClick = async () => {
    setCode(JSON.stringify({ snr: props.data ? props.data : "" }));
    onOpen(); // Open the modal after setting the image URL
  };

  return (
    <div>
      <MdQrCode
        className={`heading-lg ${props.class} hover:cursor-pointer`}
        onClick={() => {
          handleClick();
        }}
      />
      <PortalManager zIndex={2000}>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          closeOnOverlayClick
          onOverlayClick={onClose}
        >
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent
            zIndex={2500}
            className="top-[6vh] mx-2 rounded-md bg-white sm:top-[12vh] sm:!m-auto sm:!max-w-[60%] md:!max-w-[50%] lg:!max-w-[45%] xl:!max-w-[35%] 2xl:!max-w-[25%]"
          >
            {/* <ModalHeader className="heading-md px-4 py-4 text-primary">
              Photo
              <ModalCloseButton className="absolute right-4 top-4 z-10 text-txt-xs text-primary" />
            </ModalHeader> */}
            <ModalBody className="m-12 flex items-center justify-center">
              <QRCode
                size={256}
                style={{ height: "auto", width: "auto" }}
                value={code}
                viewBox={`0 0 256 256`}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </PortalManager>
    </div>
  );
};

export default QrCodeButton;
