import { useAuth } from "api/hooks/useAuth";
import { t } from "i18next";
import { shallowEqual, useSelector } from "react-redux";
import { userInformation } from "store/user/userSelectors";
import ProfileCard from "widgets/cards/profile";
import InformationForm, {
  InformationFormType,
} from "widgets/forms/profile/information";

const Information = () => {
  const auth = useAuth();
  const userInfo = useSelector(userInformation, shallowEqual);

  const doUpdateAttributes = (formData: InformationFormType) => {
    auth.updateUserAttributes(formData);
  };

  return (
    <ProfileCard
      title={t("profile.account.settings.title")}
      description={t("profile.account.settings.description")}
      bgColor="bg-white"
      extra="h-full"
    >
      <div className="flex-grow"></div>

      <InformationForm
        submit={doUpdateAttributes}
        defaultValues={{}}
        values={userInfo}
      />
    </ProfileCard>
  );
};

export default Information;
