const Badge = (props: {
  id?: string;
  label: string;
  variant?: "default" | "fill" | "outline";
  extra?: string;
  color?:
    | "red"
    | "blue"
    | "green"
    | "yellow"
    | "orange"
    | "teal"
    | "navy"
    | "lime"
    | "cyan"
    | "pink"
    | "purple"
    | "amber"
    | "indigo"
    | "gray";
  [x: string]: any;
}) => {
  const { extra, variant, color, label, id, ...rest } = props;

  const colorClasses = {
    red: "text-red-700 bg-red-100 border-red-500",
    blue: "text-blue-700 bg-blue-100 border-blue-500",
    green: "text-green-700 bg-green-100 border-green-500",
    yellow: "text-yellow-700 bg-yellow-100 border-yellow-500",
    orange: "text-orange-700 bg-orange-100 border-orange-500",
    teal: "text-teal-700 bg-teal-100 border-teal-500",
    navy: "text-navy-700 bg-navy-100 border-navy-500",
    lime: "text-lime-700 bg-lime-100 border-lime-500",
    cyan: "text-cyan-700 bg-cyan-100 border-cyan-500",
    pink: "text-pink-700 bg-pink-100 border-pink-500",
    purple: "text-purple-700 bg-purple-100 border-purple-500",
    amber: "text-amber-700 bg-amber-100 border-amber-500",
    indigo: "text-indigo-700 bg-indigo-100 border-indigo-500",
    gray: "text-gray-700 bg-gray-100 border-gray-500",
  };

  const getVariantClasses = () => {
    switch (variant) {
      case "fill":
        return `text-white bg-${color}-500`;
      case "outline":
        return `text-${color}-500 border border-${color}-500 bg-transparent`;
      default:
        return colorClasses[color] || "";
    }
  };

  return (
    <div
      id={id}
      className={`flex items-center justify-center rounded-md px-2 py-1 text-sm font-bold uppercase ${getVariantClasses()} ${extra}`}
      {...rest}
    >
      {label}
    </div>
  );
};

export default Badge;
