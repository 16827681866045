import * as z from "zod";
import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import GenericButton from "widgets/shared/buttons/generic";
import ComboBox from "widgets/shared/inputs/combobox";
import LabeledField from "providers/labeledField";
import CircleButton from "widgets/shared/buttons/circle-button";
import { MdAdd, MdClose } from "react-icons/md";
import { useEffect } from "react";

// Schema definition for the form
const SewerageRateSchema = z.object({
  type: z.enum(["fixed", "steps"]),
  fixedRate: z.number().optional(), // for "fixed" type
  steps: z
    .array(
      z
        .object({
          stepStart: z.number(),
          stepEnd: z.number().optional(), // Last step does not require stepEnd
          rate: z.number(),
        })
        .refine(
          (step) => {
            if (step.stepEnd !== undefined) {
              return step.stepEnd > step.stepStart;
            }
            return true; // Valid if stepEnd is undefined (for the last step)
          },
          {
            message: "stepEnd must be greater than stepStart",
            path: ["stepEnd"],
          }
        )
    )
    .optional(),
  percentage: z.number().min(1).max(100),
});

export type SewerageRateFormType = z.infer<typeof SewerageRateSchema>;

// Props for the form
type SewerageRateFormProps = {
  submit: (data: SewerageRateFormType) => void;
  onClose: () => void;
  defaultValues?: SewerageRateFormType;
  values?: SewerageRateFormType;
  loading?: boolean;
  disabled?: boolean;
};

const SewerageRateForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
  disabled,
}: SewerageRateFormProps) => {
  const { t } = useTranslation();

  const form = useForm<SewerageRateFormType>({
    resolver: zodResolver(SewerageRateSchema),

    values,
  });

  const { control, watch, handleSubmit, formState, setValue, getValues } = form;
  const { isDirty, errors } = formState;
  const watchType = watch("type"); // Watch the type (fixed or steps)

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "steps", // Handle array for steps
  });

  // Automatically submit the form if valid
  const onSubmit = async (formData: SewerageRateFormType) => {
    try {
      await submit(formData);
      form.reset(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // Ensure that the last step has no stepEnd
  useEffect(() => {
    if (fields.length > 0) {
      const lastStep = getValues(`steps.${fields.length - 1}`);
      if (lastStep.stepEnd) {
        setValue(`steps.${fields.length - 1}.stepEnd`, undefined);
      }
    }
  }, [fields, setValue, getValues]);

  const handleAddStep = () => {
    if (fields.length > 0) {
      const lastStep = getValues(`steps.${fields.length - 1}`);
      const newStepStart = lastStep.stepStart || 0;

      // Add new step before the last "open-ended" step
      insert(fields.length - 1, {
        stepStart: newStepStart,
        stepEnd: 0,
        rate: 0,
      });
    } else {
      append({ stepStart: 0, stepEnd: undefined, rate: 0 });
    }
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        {/* Rate type selector */}
        <div className="flex w-full flex-row items-end justify-between pt-1">
          {!disabled && (
            <FormField
              control={control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{"Rates Type"}</FormLabel>
                  <FormControl>
                    <ComboBox
                      items={[
                        { label: "Fixed Rates", value: "fixed" },
                        { label: "Step Rates", value: "steps" },
                      ]}
                      placeholder={t("form.placeholder.device.model")}
                      labelKey="label"
                      valueKey="value"
                      id="model"
                      type="text"
                      sizes="md"
                      state={errors.type ? "error" : undefined}
                      {...field}
                      onFocus={clearErrors}
                      clearIcon={false}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <FormField
            control={control}
            name="percentage"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{"Percentage"}</FormLabel>
                <FormControl>
                  <InputField
                    sizes="md"
                    placeholder={t("form.fixed.rate.placeholder")}
                    id="percentage"
                    type="number"
                    {...field}
                    disabled={disabled}
                    state={errors.percentage ? "error" : undefined}
                    onFocus={clearErrors}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {!disabled && watchType === "steps" && (
            <div className="flex gap-2">
              <CircleButton
                // label="Add"
                icon={MdAdd}
                onClick={handleAddStep}
                disabled={disabled}
                size="md"
                variant="outline"
              />

              <CircleButton
                icon={MdClose}
                onClick={() => {
                  if (fields.length > 2) {
                    remove(fields.length - 2);
                  }
                }}
                disabled={disabled || fields.length <= 1}
                size="md"
                variant="outline"
              />
            </div>
          )}
        </div>

        {/* Fixed rate input */}
        {watchType === "fixed" && (
          <FormField
            control={control}
            name="fixedRate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{"FIXED RATE: ( R/kl )"}</FormLabel>
                <FormControl>
                  <InputField
                    sizes="md"
                    placeholder={t("form.fixed.rate.placeholder")}
                    id="fixedRate"
                    type="number"
                    {...field}
                    disabled={disabled}
                    state={errors.fixedRate ? "error" : undefined}
                    onFocus={clearErrors}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {/* Stepped rate inputs */}
        {watchType === "steps" && (
          <>
            <div className="grid grid-cols-3 gap-2">
              <LabeledField label={"FROM: ( kl )"} id={""} children={""} />
              <LabeledField label={"TO: ( kl )"} id={""} children={""} />
              <LabeledField
                label={"STEP RATE: ( R/kl )"}
                id={""}
                children={""}
              />
            </div>

            {fields.map((item, index) => (
              <div key={item.id} className="grid grid-cols-3 gap-2">
                <FormField
                  control={control}
                  name={`steps.${index}.stepStart`}
                  render={({ field }) => (
                    <FormItem>
                      {/* <FormLabel>{t("form.step.start")}</FormLabel> */}
                      <FormControl>
                        <InputField
                          sizes="md"
                          placeholder={t("form.step.start.placeholder")}
                          id={`stepStart-${index}`}
                          type="number"
                          {...field}
                          disabled={true}
                          onFocus={clearErrors}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={control}
                  name={`steps.${index}.stepEnd`}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <InputField
                          sizes="md"
                          id={`stepEnd-${index}`}
                          type="number"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            if (index < fields.length - 1) {
                              setValue(`steps.${index + 1}.stepStart`, e);
                            }
                          }}
                          disabled={disabled}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={control}
                  name={`steps.${index}.rate`}
                  render={({ field }) => (
                    <FormItem>
                      {/* <FormLabel>{t("form.rate")}</FormLabel> */}
                      <FormControl>
                        <InputField
                          sizes="md"
                          placeholder={t("form.rate.placeholder")}
                          id={`rate-${index}`}
                          type="number"
                          {...field}
                          disabled={disabled}
                          onFocus={clearErrors}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            ))}
          </>
        )}

        {/* Submit Button */}
        {isDirty && formState.isValid && !disabled && (
          <div className="flex w-full flex-col pt-4">
            <div className="flex justify-end">
              <GenericButton
                text={"Submit"}
                disabled={!isDirty}
                loading={loading || false}
                type="submit"
                size="md"
              />
            </div>
          </div>
        )}
      </form>
    </Form>
  );
};

export default SewerageRateForm;
