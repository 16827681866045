import { dataUsage } from "api/data";
import { DataReqBody } from "api/types/data";
import { Device } from "api/types/device";
import BarChart from "components/charts/BarChart";
import { useEffect, useState } from "react";

import { formatDate, formatDateToZ } from "util/datelib";
import GenericCard from "widgets/cards/generic";
import Combobox from "widgets/shared/inputs/combobox";
import DatePickerField from "widgets/shared/inputs/datePicker";
import PropagateLoader from "react-spinners/PropagateLoader";
import { t } from "i18next";
import { DeviceConfiguration } from "models/devices/configuration";
import { UsageChartDropdown } from "models/dropdowns/usageChart";
import { getChartColors } from "util/device";

const options = {
  chart: {
    type: "bar",
    toolbar: { show: false },
    stacked: true,
    animations: { enabled: true, easing: "easeinout", speed: 800 },
    height: "90%",
    width: "100%",
    responsive: [
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 5,
              columnWidth: "70%",
            },
          },
          xaxis: {
            labels: { rotate: -30 },
          },
        },
      },
    ],
  },
  dataLabels: {
    enabled: false,
  },

  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "40%",
      borderRadiusApplication: "top",
      borderRadiusWhenStacked: "last",
    },
  },

  xaxis: {
    labels: {
      rotate: -45,
      formatter: function (value: string) {
        return `${formatDate(value, "MMMyyyy")}`;
      },
      style: {
        lineHeight: "1.125rem",
        letterSpacing: "-0.02em",
        fontWeight: "500",
        fontFamily: "Lato, sans-serif",
        colors: "var(--color-400)",
      },
    },
    tickPlacement: "off",
    type: "category",
  },

  yaxis: {
    labels: {
      formatter: function (value: number) {
        return `${value.toFixed(2)} kl`;
      },
      style: {
        lineHeight: "1.125rem",
        letterSpacing: "-0.02em",
        fontWeight: "700",
        fontFamily: "Lato, sans-serif",
        colors: ["var(--color-50)"],
      },
    },

    min: 0,
    max: 1,
    tickAmount: 3,
  },

  fill: {
    type: "solid",
    colors: ["var(--color-400)", "#E1E9F8", "#E1E9F8"],
  },

  tooltip: {
    custom: function (value: any) {
      const see = value.series[value.seriesIndex];
      const xLabel = value.w.globals.labels[value.dataPointIndex];
      return `
      <div class="custom-tooltip">
          <div>${formatDate(xLabel, "MMMyyyy")}</div>
          <div>Daily Usage: ${see[value.dataPointIndex]} kl</div>
          </div>
        `;
    },
  },

  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 3,
  },
};

// Define the props for the Role component
interface GeneralProps {
  device?: Device;
  config?: DeviceConfiguration;
  // onSubmit?: (formData: UserEditFormType) => void;
}

const Usage: React.FC<GeneralProps> = ({ device, config }) => {
  const { color0, color1 } = getChartColors(config || undefined);
  const [chartOptions, setChartOptions] = useState(options);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [date, setDate] = useState<any>(new Date());
  const [filter, setFilter] = useState<any>({
    calendar: "year",
    xFormat: "month",
    currentBucket: "day",
  });

  const setXFormat = (filter: string) => {
    if (filter === "year") {
      setFilter({ calendar: filter, xFormat: "month", currentBucket: "day" });
    } else {
      setFilter({ calendar: filter, xFormat: "year", currentBucket: "month" });
    }
  };

  const fetchData = async () => {
    setLoading(true);

    const req: DataReqBody = {
      snr: device?.snr,
      from: formatDateToZ(date, false, filter.xFormat),
      to: formatDateToZ(date, true, filter.xFormat),
      bucket: filter.currentBucket,
      sensors: [config.raw],
    };

    const { data: newData, error: err, isLoading } = await dataUsage(req);
    setLoading(isLoading);

    if (!err) {
      // Extract start and end dates from the newData
      const firstTimestamp = newData.length > 0 ? newData[0].timestamp : date;
      const lastTimestamp =
        newData.length > 0 ? newData[newData.length - 1].timestamp : date;

      // Update state for date range
      setStartDate(firstTimestamp);
      setEndDate(lastTimestamp);

      const set0 = [];
      const set1 = [];
      for (let index = 0; index < newData.length; index++) {
        const element = newData[index];
        const usage = {
          x: element._id,
          y:
            config.units === "water"
              ? element[config.raw] / 1000
              : element[config.raw],
        };
        const limit = {
          x: formatDate(element.timestamp, filter.currentBucket), // Format based on the filter
          y:
            config.units === "water"
              ? Math.max(
                  0,
                  (device?.dailyUsageLimit - element[config.raw]) / 1000
                )
              : Math.max(0, device?.dailyUsageLimit - element[config.raw]), // Ensure y is not negative
        };
        set1.push(limit);
        set0.push(usage);
      }
      // Get the max value of y from the set0 array
      const maxY = Math.max(...set0.map((item) => item.y));

      const dynamicMaxY = Math.max(maxY, device?.dailyUsageLimit / 1000);

      // Update the yaxis.max in chart options
      const updatedOptions = {
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,
          labels: {
            ...chartOptions.xaxis.labels,
            formatter: (value: string) =>
              formatDate(value, filter.currentBucket), // Use dynamic xFormat
            style: {
              ...chartOptions.xaxis.labels.style,
              colors: color0,
            },
          },
        },
        yaxis: {
          ...chartOptions.yaxis,
          labels: {
            ...chartOptions.yaxis.labels,
            formatter: (value: number) =>
              `${value.toFixed(2)} ${t("device.units", {
                context: config.units,
              })}`,
            // style: {
            //   ...chartOptions.yaxis.labels.style,
            //   colors: color0,
            // },
          },
          max: dynamicMaxY * 1.1,
        },
        tooltip: {
          custom: (value: any) => {
            const seriesValue = value.series[value.seriesIndex];
            const xLabel = value.w.globals.labels[value.dataPointIndex];
            return `
              <div class="custom-tooltip">
                <div>${formatDate(xLabel, filter.currentBucket)}</div>
                <div>${t("device.tooltip.description", {
                  context: config.units,
                })} ${seriesValue[value.dataPointIndex].toFixed(2)} ${t(
              "device.units",
              { context: config.units }
            )}</div>
              </div>`;
          },
        },
        fill: {
          ...chartOptions.fill,
          colors: [color0, color1],
        },
      };

      // Update state for options and data
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        ...updatedOptions, // Ensure you are spreading existing options
      }));

      const data = [
        {
          name: "set0",
          data: set0,
        },
      ];

      if (device?.dailyUsageLimit && filter.calendar !== "decade") {
        data.push({
          name: "set1",
          data: set1,
        });
      }

      setData(data);
    }
  };

  useEffect(() => {
    if (device?.snr) {
      fetchData();
    }
  }, [device, date, filter]);

  const getDescription = () => {
    if (filter.calendar === "year") {
      return `${formatDate(startDate, "ddMMMyyyy")} - ${formatDate(
        endDate,
        "ddMMMyyyy"
      )}`;
    } else if (filter.calendar === "decade") {
      return `${formatDate(startDate, "MMMyyyy")} - ${formatDate(
        endDate,
        "MMMyyyy"
      )}`;
    }
    return "";
  };

  return (
    <GenericCard
      title={t("chart.usage.title", {
        context: filter.calendar,
      })}
      description={getDescription()}
      bgColor="bg-white"
      extraTitle="text-primary heading-md"
      extraDescription="text-tertiary text-txt-sm"
      topRight={
        <div className="flex justify-center gap-2">
          <Combobox
            id="user-select-calendar"
            placeholder=""
            variant="filter"
            placement="top"
            sizes="sm"
            clearIcon={false}
            value={filter.calendar}
            onChange={(item) => {
              setXFormat(item);
            }}
            items={UsageChartDropdown}
          />
          <DatePickerField
            id={"1"}
            sizes={"xs"}
            value={date}
            onChange={(val) => {
              setDate(val); // Set new date and trigger useEffect
            }}
            maxDate={new Date()}
            maxDetail={filter.calendar}
          />
        </div>
      }
    >
      <div className="h-full min-h-64 w-full overflow-x-auto">
        {data.length > 0 && !loading ? (
          <>
            <div className="ml-4 flex items-center gap-2 text-txt-sm text-tertiary">
              <div
                className="h-4 w-4 rounded-sm"
                style={{ backgroundColor: color0 }}
              ></div>
              <div>
                {t("chart.usage.legend.one", {
                  context: config.units,
                })}
              </div>
              {device?.dailyUsageLimit && filter.calendar === "year" && (
                <>
                  <div className={`h-4 w-4 rounded-sm bg-[${color1}]`}></div>
                  <div>
                    {t("chart.usage.legend.two", {
                      context: config.units,
                    })}
                  </div>
                </>
              )}
            </div>
            <BarChart chartData={data} chartOptions={chartOptions} />
          </>
        ) : (
          <div className="mt-12 flex items-center justify-center bg-opacity-50">
            <PropagateLoader loading={loading} color="var(--color-500)" />
          </div>
        )}
      </div>
    </GenericCard>
  );
};

export default Usage;
