import makeApiRequest from "./clients/apiRequest";
import { gateway } from "./clients/baseClient";
import { versionApiPath } from "./common";
import { ENDPOINTS } from "./endpoints";
import { ResponseData } from "./types/api";
import { DataReqBody } from "./types/data";

export async function dataUsage(body: DataReqBody): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DATA.USAGE}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function dataAverage(body: DataReqBody): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DATA.AVERAGE.INDEX}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}

export async function dataAverageTimeSeries(
  body: DataReqBody
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DATA.AVERAGE.TIME_SERIES}`,
    body: body,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    true
  );
}
