const GenericButton = ({
  loading = false,
  disabled = false,
  size = "lg",
  variant = "solid",
  type = "button",
  text,
  onClick,
  extra,
}: {
  loading?: boolean;
  disabled?: boolean;
  size?: "lg" | "md" | "sm" | "xs";
  variant?: "solid" | "outline" | "ghost" | "link";
  type?: "button" | "submit";
  text: string;
  onClick?: () => void;
  extra?: string;
}) => {
  let typeClass = "";
  let sizeClass = "";
  let opacityClass = disabled || loading ? "opacity-40" : "opacity-100";

  switch (variant) {
    case "solid":
      typeClass =
        "flex items-center justify-center linear text-white bg-brand-500 hover:bg-brand-50 focus:bg-brand-50";
      break;
    case "outline":
      typeClass = `flex items-center justify-center linear border border-brand-500 text-brand-50 bg-transparent hover:bg-brand-300 hover:border-brand-50 focus:bg-brand-300`;
      break;
    case "ghost":
      typeClass =
        typeClass = `flex items-center justify-center linear text-brand-50 bg-transparent hover:bg-white-alpha-500 hover:border-brand-50 focus:bg-brand-50 focus:text-brand-200`;
      break;
    case "link":
      typeClass =
        typeClass = `flex items-center justify-center linear underline text-brand-50 bg-transparent hover:!underline focus:!underline`;
      break;
  }

  switch (size) {
    case "lg":
      sizeClass = "h-12 px-6 heading-md";
      break;
    case "md":
      sizeClass = "h-10 px-4 heading-sm";
      break;
    case "sm":
      sizeClass = "h-8 px-3  heading-sm";
      break;
    case "xs":
      sizeClass = "h-6 px-2  heading-xs";
      break;
  }

  return (
    <button
      className={`rounded-2xl ${opacityClass} ${sizeClass} ${typeClass} ${extra}`}
      disabled={loading || disabled}
      onClick={onClick}
      type={type}
    >
      {text}
    </button>
  );
};

export default GenericButton;
