import { Auth } from "aws-amplify";
import axios, { AxiosInstance } from "axios";

export const baseURL = `https://dashboard.atomservices.io/gw/iot`;

const newAxios = function () {
  return axios.create({
    baseURL,
  });
};

const OnRequestSuccess = async (config: any) => {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers["jwt_token"] = token;
    }
  } catch (error) {
    console.log("Error fetching token:", error);
  }
  return config;
};

const onRequestError = (err: any) => {
  return Promise.reject(err);
};

const onResponseError = async (err: any) => {
  return Promise.reject(err);
};

const setupAxiosInterceptors = (axios: AxiosInstance, requestSuccess: any) => {
  if (axios.interceptors) {
    axios.interceptors.request.use(requestSuccess, onRequestError);
    axios.interceptors.response.use((res: any) => res, onResponseError);
  }
};

export const gateway = newAxios();
setupAxiosInterceptors(gateway, OnRequestSuccess);
