import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-date-picker";

import { InputHTMLAttributes } from "react";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { MdCalendarToday } from "react-icons/md";

// LooseValue represents the types supported by DateRangePicker
type LooseValue = Date | [Date, Date] | null;

interface DatePickerProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
  id: string;
  extra?: string;
  value?: LooseValue; // Accept default value from the parent
  onChange?: (value: LooseValue) => void; // Correct onChange type
  sizes?: "lg" | "md" | "sm" | "xs";
  minDate?: Date;
  maxDate?: Date;
  maxDetail?: "month" | "year" | "decade" | "century";
}

const DatePickerField = forwardRef<HTMLInputElement, DatePickerProps>(
  (props, ref) => {
    const {
      id,
      extra,
      sizes = "lg",
      minDate,
      maxDate,
      maxDetail = "month",
      value: defaultValue, // value from parent as defaultValue
      onChange,
    } = props;

    // State for managing date range within the component
    const [value, setValue] = useState<LooseValue>(
      defaultValue || [new Date(), new Date()]
    );

    useEffect(() => {
      // Update local state when parent value changes
      if (defaultValue) {
        setValue(defaultValue);
      }
    }, [defaultValue]);

    const handleChange = (selectedValue: LooseValue) => {
      setValue(selectedValue);
      if (onChange) {
        onChange(selectedValue);
      }
    };

    let sizeClass = "";

    switch (sizes) {
      case "lg":
        sizeClass = "h-12 w-12 text-txt-lg";
        break;
      case "md":
        sizeClass = "h-10 w-10 text-txt-md";
        break;
      case "sm":
        sizeClass = "h-8 w-8 text-txt-sm";
        break;
      case "xs":
        sizeClass = "h-6 w-6 text-txt-xs";
        break;
    }

    return (
      <div className={`${extra}`}>
        <DatePicker
          id={id}
          onChange={handleChange}
          value={value}
          clearIcon={null}
          showLeadingZeros
          calendarIcon={
            <MdCalendarToday className={`${sizeClass} text-primary`} />
          }
          className={`${sizeClass} text-primary`}
          maxDate={maxDate}
          minDate={minDate}
          maxDetail={maxDetail}
        />
      </div>
    );
  }
);

export default DatePickerField;
