import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setLoading } from "store/appSlice";

import { ViewPort } from "models/constants/styling";
import Card from "components/card";
import General from "./components/general";
import { getSite } from "api/site";
import { Site } from "api/types/site";
import Rates from "./components/rates";
import Configuration from "./components/configuration";

const Sites = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  // Extract the filter parameter
  const id = searchParams.get("id");

  const [site, setSite] = useState<Site>(null);

  const fetchData = async () => {
    dispatch(setLoading(true));
    const { data, status, isLoading } = await getSite(id);
    dispatch(setLoading(isLoading));
    if (status && status === 200) {
      setSite(data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div
      className={`mt-6 grid w-full grid-cols-1 gap-y-4 ${ViewPort.contentFitHeight}`}
    >
      <div className="flex flex-col gap-4 lg:flex-row">
        {/* Group for CARD 1, 2, and 3 taking 2/3 of the row */}
        {/* <div className="flex flex-col gap-4"> */}
        {/* <div className="flex flex-col gap-4 lg:flex-row"> */}

        <General site={site} onUpdate={fetchData} />

        {/* </div> */}
        <Configuration site={site} onUpdate={fetchData} />
        {/* </div> */}
        {/* CARD 4 taking 1/3 of the row */}
        {/* <div className="lg:w-1/3">
          <Card />
        </div> */}
      </div>

      <div className="flex flex-col gap-0 lg:flex-row">
        <Rates site={site} onUpdate={fetchData} />
      </div>
    </div>
  );
};

export default Sites;
