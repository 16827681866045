import { deviceSet } from "api/device";
import { Device, DeviceSetBody } from "api/types/device";
import { t } from "i18next";
import { DeviceConfiguration } from "models/devices/configuration";
import { useState } from "react";
import { MdEditDocument } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import GenericCard from "widgets/cards/generic";
import ElectricityConfigurationForm, {
  ElectricityConfigurationFormType,
} from "widgets/forms/devices/configuration/electricity";
import TemperatureConfigurationForm, {
  TemperatureConfigurationFormType,
} from "widgets/forms/devices/configuration/temperature";
import WaterConfigurationForm, {
  WaterConfigurationFormType,
} from "widgets/forms/devices/configuration/water";

interface GeneralProps {
  device?: Device;
  config?: DeviceConfiguration;
  onUpdate?: () => void;
}

const Configuration: React.FC<GeneralProps> = ({
  device,
  config,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  const doDeviceSet = async (formData: any) => {
    const body: DeviceSetBody = {
      snr: device?.snr,
    };
    if (formData?.dailyUsageLimit) {
      body.dailyUsageLimit = formData.dailyUsageLimit;
    }
    if (formData?.triggerLow) {
      body.triggerLow = formData.triggerLow;
    }
    if (formData?.triggerHigh) {
      body.triggerHigh = formData.triggerHigh;
    }
    handleSet(body);
  };

  const handleSet = async (body: DeviceSetBody) => {
    dispatch(setLoading(true));
    const { status, isLoading } = await deviceSet(body);
    dispatch(setLoading(isLoading));

    if (status && status === 200) {
      setEdit(false);
      onUpdate();
    }
  };

  return (
    <GenericCard
      title={t("device.configuration")}
      bgColor={`${
        config?.units === "water"
          ? "bg-brand-400"
          : config?.units === "temperature"
          ? "bg-brand-500"
          : "bg-brand-50"
      }`}
      extraTitle="text-secondary heading-md"
      topRight={
        <MdEditDocument
          className={`heading-lg text-primary hover:cursor-pointer ${
            config?.units === "water" ? "text-primary" : "text-secondary"
          }`}
          onClick={() => {
            setEdit(!edit);
          }}
        />
      }
    >
      {config && config.units === "water" && (
        <WaterConfigurationForm
          submit={doDeviceSet}
          onClose={() => {}}
          values={{ dailyUsageLimit: device?.dailyUsageLimit }}
          disabled={!edit}
        />
      )}

      {config && config.units === "electricity" && (
        <ElectricityConfigurationForm
          submit={doDeviceSet}
          onClose={() => {}}
          values={{ dailyUsageLimit: device?.dailyUsageLimit }}
          disabled={!edit}
        />
      )}
      {config && config.units === "temperature" && (
        <TemperatureConfigurationForm
          submit={doDeviceSet}
          onClose={() => {}}
          values={{
            triggerLow: device?.triggerLow,
            triggerHigh: device?.triggerHigh,
          }}
          disabled={!edit}
        />
      )}
    </GenericCard>
  );
};

export default Configuration;
