import PinInput from "react-pin-input";

// Custom components
function PinInputs(props: {
  value: any;
  onChange: any;
  disabled?: boolean;
  state?: "error" | "success" | "default";
}) {
  const { value, onChange, disabled = false, state = "default" } = props;

  // Determine styles based on state and disabled status
  const getBorderColor = () => {
    if (disabled) return "gray-200";
    if (state === "error") return "red-500";
    if (state === "success") return "green-500";
    return "brand-700";
  };

  const getTextColor = () => {
    if (disabled) return "gray-200";
    if (state === "error") return "red-500";
    if (state === "success") return "green-500";
    return "text-primary dark:text-darkPrimary";
  };

  const getPlaceholderColor = () => {
    if (state === "error") return "text-red-500";
    if (state === "success") return "text-green-500";
    return "placeholder:text-gray-500";
  };

  return (
    <div className="flex w-full items-center">
      <PinInput
        length={6}
        initialValue={value}
        onChange={(value) => onChange(value)}
        inputMode="text"
        style={{ display: "flex", flexGrow: 1 }}
        inputStyle={{
          margin: "0 4px",
          borderWidth: "1px",
          width: "48px",
          height: "48px",
          borderColor: `#A6A8C9`,
          borderRadius: "16px",
          color: `#${getTextColor()}`,
          fontSize: "24px",
          backgroundColor: disabled ? "#F3F4F6" : "transparent",
          pointerEvents: disabled ? "none" : "auto",
        }}
        inputFocusStyle={{
          borderColor: `#1D4A64`,
          borderWidth: "1px",
          backgroundColor: disabled ? "#F3F4F6" : "white",
        }}
        onComplete={(value) => onChange(value)}
        autoSelect={true}
        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
      />
    </div>
  );
}

export default PinInputs;
