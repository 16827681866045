export const ReportConfiguration = [
  {
    id: 1,
    name: "Raw",
    classSelect: true,
    singleSelect: true,
    multiSelect: false,
    dateRange: true,
  },
  {
    id: 2,
    name: "Water Report",
    classSelect: true,
    singleSelect: false,
    multiSelect: true,
    dateRange: true,
  },
];
