import { useAuth } from "api/hooks/useAuth";
import { t } from "i18next";
import ProfileCard from "widgets/cards/profile";
import ChangePasswordForm, {
  ChangePasswordFormType,
} from "widgets/forms/profile/changePassword";

const Password = () => {
  const auth = useAuth();

  const doChangePassword = (formData: ChangePasswordFormType) => {
    auth.changePassword(formData);
  };

  return (
    <ProfileCard
      title={t("profile.change.password.title")}
      description={t("profile.change.password.description")}
      extra="bg-white h-full"
    >
      <div className="flex-grow"></div>
      <ChangePasswordForm submit={doChangePassword} />
    </ProfileCard>
  );
};

export default Password;
