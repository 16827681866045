import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ComboBox from "widgets/shared/inputs/combobox";
import GenericButton from "widgets/shared/buttons/generic";
import { AddDeviceDropdown } from "models/devices/add";
// Schema
const DeviceSchema = z.object({
  snr: z.string().min(1, { message: i18next.t("zod.error.required") }),
  name: z.string().min(1, { message: i18next.t("zod.error.required") }),
  model: z.string().min(1, { message: i18next.t("zod.error.required") }),
});

export type DeviceFormType = z.infer<typeof DeviceSchema>;

// Props
type DeviceFormProps = {
  submit: (data: DeviceFormType) => void;
  onClose: () => void;
  defaultValues?: DeviceFormType;
  values?: DeviceFormType;
  loading?: boolean;
};

const DeviceForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
}: DeviceFormProps) => {
  const form = useForm<DeviceFormType>({
    resolver: zodResolver(DeviceSchema),
    defaultValues: {
      snr: "",
      name: "",
      model: "",
    },
    values,
  });

  const { t } = useTranslation();
  const { errors, isDirty } = form.formState;

  const onSubmit = async (formData: DeviceFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className=" space-y-6">
        <div className="mt-[12px] grid grid-cols-1 gap-3 ">
          <FormField
            control={form.control}
            name="snr"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.device.snr")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.device.snr")}
                    id="snr"
                    type="text"
                    state={errors.snr ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.device.name")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.device.name")}
                    id="name"
                    type="text"
                    state={errors.name ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="model"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.device.model")}</FormLabel>
                <FormControl>
                  <ComboBox
                    items={AddDeviceDropdown}
                    placeholder={t("form.placeholder.device.model")}
                    labelKey="name"
                    valueKey="code"
                    id="model"
                    type="text"
                    state={errors.model ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-end py-4">
          <div className="flex gap-2">
            <GenericButton
              onClick={onClose}
              text={t("button.action.cancel")}
              size="md"
              variant="ghost"
            />

            <GenericButton
              type="submit"
              text={t("button.action.ok")}
              disabled={!isDirty}
              loading={loading || false}
              size="md"
            />
          </div>
        </div>
      </form>
    </Form>
  );
};

export default DeviceForm;
