import React, { useCallback, useEffect, useState } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import navbarimage from "assets/img/layout/Navbar.png";
import { BsArrowBarUp } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import Configurator from "./Configurator";
// import { RiMoonFill, RiSunFill } from 'react-icons/ri';
// import Configurator from './Configurator';
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import avatar from "assets/img/avatars/avatar4.png";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { useAuth } from "api/hooks/useAuth";
import { shallowEqual, useSelector } from "react-redux";
import { userFullname } from "store/user/userSelectors";
import { t } from "i18next";
import water from "assets/img/layout/atom_water_bg.png";
import electricity from "assets/img/layout/atom_electricity_bg.png";
import temperature from "assets/img/layout/atom_temperature_bg.png";
import { SearchInput } from "widgets/shared/inputs/search";
import { deviceGlobalSearch } from "api/device";
import GlobalSearchDialog from "widgets/dialogs/globalSearch";
import { DeviceGlobalSearchRes } from "api/types/device";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
  navbarType?: string;
  [x: string]: any;
}) => {
  const {
    onOpenSidenav,
    brandText,
    navbarType = null,
    mini,
    // setMini,
    // theme,
    // setTheme,
    hovered,
  } = props;
  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains("dark")
  );
  const navigate = useNavigate();
  const fullname = useSelector(userFullname, shallowEqual);
  const [filter, setFilter] = useState("");
  const [device, setDevice] = useState<DeviceGlobalSearchRes>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { signOut } = useAuth();
  const logout = () => {
    signOut();
  };

  const fetchData = async () => {
    const { data, status, isLoading, error } = await deviceGlobalSearch(filter);

    if (status && status === 200) {
      setDevice(data);
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (filter && filter.length > 0) {
      fetchData();
    }
  }, [filter]);
  return (
    <>
      <GlobalSearchDialog
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        device={device}
      />
      <nav
        className={` duration-175 linear fixed right-3 top-3 flex flex-row flex-wrap items-center justify-between rounded-xl  transition-all ${
          mini === false
            ? "w-[calc(100vw_-_24px)] md:w-[calc(100vw_-_42px)] lg:w-[calc(100vw_-_42px)] xl:w-[calc(100vw_-_345px)] 2xl:w-[calc(100vw_-_345px)]"
            : mini === true && hovered === true
            ? "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]"
            : "w-[calc(100vw_-_4%)] md:w-[calc(100vw_-_4%)] lg:w-[calc(100vw_-_4%)] xl:w-[calc(100vw_-_150px)] 2xl:w-[calc(100vw_-_165px)]"
        }  ${
          navbarType ? "p-4 " : "bg-white/30 p-2 backdrop-blur-xl"
        } z-10  dark:bg-[#0b14374d] md:right-[25px] md:top-4 xl:top-[20px]`}
      >
        <div className="ml-[6px]">
          <div className="h-6 w-[224px] pt-1">
            <a
              className={`text-txt-md ${
                navbarType ? "text-white" : "text-tertiary"
              }`}
              href=" "
            >
              {t("navbar.title")}
              <span
                className={`mx-1 text-txt-md ${
                  navbarType ? "text-white" : "text-tertiary"
                }`}
              >
                /
              </span>
            </a>
            <Link
              className={`text-txt-md ${
                navbarType ? "text-white" : "text-tertiary"
              }`}
              to="#"
            >
              {brandText}
            </Link>
          </div>
          <p className="shrink ">
            <Link
              to="#"
              className={`heading-xl ${
                navbarType ? "text-white" : "text-primary"
              }`}
            >
              {brandText}
            </Link>
          </p>
        </div>

        <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
          <div className="bg-lightPrimary flex h-full items-center rounded-full  dark:bg-navy-900 xl:w-[225px]">
            <SearchInput
              sizes="md"
              onChange={(filters) => {
                setFilter(filters);
              }}
              type="text"
              value={filter}
              extra="rounded-full"
            />
            {/* <p className="pl-3 pr-2 text-xl">
              <FiSearch className="h-4 w-4 text-primary dark:text-darkPrimary" />
            </p>
            <input
              type="text"
              placeholder="Search..."
              className="bg-lightPrimary block h-full w-full rounded-full text-txt-sm text-tertiary outline-none placeholder:!text-gray-400  dark:placeholder:!text-white sm:w-fit"
            /> */}
          </div>
          <span
            className="heading-xl flex cursor-pointer text-primary dark:text-darkPrimary xl:hidden "
            onClick={onOpenSidenav}
          >
            <FiAlignJustify className="h-5 w-5" />
          </span>

          <div className="flex gap-0">
            <button
              className="h-10 w-10 rounded-full"
              onClick={() => {
                if (darkmode) {
                  document.body.classList.remove("dark");
                  setDarkmode(false);
                } else {
                  document.body.classList.add("dark");
                  setDarkmode(true);
                }
              }}
            >
              <div className="h-5 w-5 cursor-pointer text-primary dark:text-darkPrimary">
                {darkmode ? (
                  <RiSunFill className="h-5 w-5" />
                ) : (
                  <RiMoonFill className="h-5 w-5" />
                )}
              </div>
            </button>

            {/* Profile & Dropdown */}
            <Dropdown
              button={
                <img
                  className="h-10 w-10 rounded-full"
                  src={avatar}
                  alt="Elon Musk"
                />
              }
              children={
                <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                  <div className="ml-4 mt-3">
                    <div className="flex items-center gap-2">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        👋 Hey, {fullname}
                      </p>{" "}
                    </div>
                  </div>
                  <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

                  <div className="ml-4 mt-3 flex flex-col">
                    <a
                      onClick={() => {
                        navigate("/admin/profile");
                      }}
                      className="cursor-pointer text-sm text-gray-800 dark:text-white hover:dark:text-white"
                    >
                      Profile Settings
                    </a>
                    <a
                      onClick={logout}
                      className="mt-3 cursor-pointer text-sm font-medium text-red-500 hover:text-red-500"
                    >
                      Log Out
                    </a>
                  </div>
                </div>
              }
              classNames={"py-2 top-8 -left-[180px] w-max"}
            />
          </div>
        </div>
      </nav>
      {navbarType === "water" && (
        <div
          // className="fixed left-80 top-0 z-0 flex h-72 w-full justify-center rounded-2xl bg-cover bg-center"
          className={` duration-175 linear fixed right-3 top-3 flex h-72 flex-row flex-wrap items-center justify-between rounded-xl bg-white/30 bg-cover bg-center transition-all ${
            mini === false
              ? "w-[calc(100vw_-_24px)] md:w-[calc(100vw_-_42px)] lg:w-[calc(100vw_-_42px)] xl:w-[calc(100vw_-_345px)] 2xl:w-[calc(100vw_-_345px)]"
              : mini === true && hovered === true
              ? "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]"
              : "w-[calc(100vw_-_4%)] md:w-[calc(100vw_-_4%)] lg:w-[calc(100vw_-_4%)] xl:w-[calc(100vw_-_150px)] 2xl:w-[calc(100vw_-_165px)]"
          }  ${
            navbarType ? "p-4" : "p-2"
          } z-0 md:right-[25px] md:top-4 xl:top-[20px]`}
          style={{ backgroundImage: `url(${water})` }}
        >
          {" "}
        </div>
      )}
    </>
  );
};

export default Navbar;
