import { deviceGet } from "api/device";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setLoading } from "store/appSlice";
import General from "./components/general";
import { Device } from "api/types/device";
import Installer from "./components/installer";
import Daily from "./components/daily";
import Configuration from "./components/configuration";
import Usage from "./components/usage";
import { ViewPort } from "models/constants/styling";
import {
  DeviceConfiguration,
  deviceConfiguration,
} from "models/devices/configuration";
import Average from "./components/average";
import Timeseries from "./components/timeseries";
import DailyAverage from "./components/dailyAverage";

const Devices = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  // Extract the filter parameter
  const snr = searchParams.get("snr");

  const [device, setDevice] = useState<Device>(null);
  const [config, setConfig] = useState<DeviceConfiguration>(null);

  const fetchData = async () => {
    dispatch(setLoading(true));
    const { data, status, isLoading, error } = await deviceGet(snr);
    dispatch(setLoading(isLoading));
    if (!error) {
      // console.log(status);
      // console.log(data);
      const config = deviceConfiguration.getDeviceByTag(data.model);
      setConfig(config);
      setDevice(data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [snr]);

  return (
    <>
      {config && (
        <div className={`z-10 mt-6 grid w-full grid-cols-1 gap-y-4  px-4`}>
          <div className="flex flex-col gap-4 lg:flex-row">
            {/* Group for CARD 1, 2, and 3 taking 2/3 of the row */}
            <div className="flex flex-col gap-4 lg:w-2/3">
              <div className="flex flex-col gap-4 lg:flex-row">
                <div className="lg:w-2/3">
                  <General
                    device={device}
                    onUpdate={fetchData}
                    config={config}
                  />
                </div>
                <div className="lg:w-1/3">
                  <Installer device={device} config={config} />
                </div>
              </div>
              <Configuration
                device={device}
                config={config}
                onUpdate={fetchData}
              />
            </div>
            {/* CARD 4 taking 1/3 of the row */}
            <div className="lg:w-1/3">
              {config.type === "usage" && (
                <Daily device={device} config={config} />
              )}
              {config.type === "average" && (
                <DailyAverage device={device} config={config} />
              )}
            </div>
          </div>

          <div className="flex flex-col gap-0 lg:flex-row">
            {config.type === "usage" && (
              <Usage device={device} config={config} />
            )}
            {config.type === "average" && (
              <Timeseries device={device} config={config} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Devices;
