import * as z from "zod";
import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import GenericButton from "widgets/shared/buttons/generic";
import ComboBox from "widgets/shared/inputs/combobox";
import LabeledField from "providers/labeledField";
import CircleButton from "widgets/shared/buttons/circle-button";
import { MdAdd, MdClose } from "react-icons/md";
import Checkbox from "widgets/shared/inputs/checkbox";

// Schema definition for the form
const OtherRateSchema = z.object({
  other: z
    .array(
      z.object({
        name: z.string().min(1, "Name is required"),
        rate: z.number().gte(0, "Rate must be a positive number"),
        type: z.enum(["water", "electricity", "sewerage", "other"]),
        active: z.boolean(),
      })
    )
    .optional(),
});

export type OtherRateFormType = z.infer<typeof OtherRateSchema>;

// Props for the form
type OtherRateFormProps = {
  submit: (data: OtherRateFormType) => void;
  onClose: () => void;
  defaultValues?: OtherRateFormType;
  values?: OtherRateFormType;
  loading?: boolean;
  disabled?: boolean;
};

const OtherRateForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
  disabled,
}: OtherRateFormProps) => {
  const { t } = useTranslation();

  const form = useForm<OtherRateFormType>({
    resolver: zodResolver(OtherRateSchema),
    values,
  });

  const { control, handleSubmit, formState, setValue } = form;
  const { isDirty, errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "other", // Manage array for other rates
  });

  const onSubmit = async (formData: OtherRateFormType) => {
    try {
      await submit(formData);
      form.reset(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleAddRate = () => {
    append({ name: "", rate: 0, active: true });
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        {/* Rate type selector */}
        <div className="flex w-full flex-row justify-end pt-1">
          {!disabled && (
            <div className="flex gap-2">
              <CircleButton
                // label="Add"
                icon={MdAdd}
                onClick={handleAddRate}
                disabled={disabled}
                size="md"
                variant="outline"
              />
            </div>
          )}
        </div>

        <>
          <div className="grid grid-cols-4 gap-2">
            {fields.length > 0 && (
              <>
                <LabeledField label={"NAME:"} id={""} children={""} />
                <LabeledField label={"RATE: ( R )"} id={""} children={""} />
                <LabeledField label={"TYPE:"} id={""} children={""} />
                <LabeledField label={"ACTIVE:"} id={""} children={""} />
              </>
            )}
          </div>

          {fields.map((item, index) => (
            <div key={item.id} className="grid grid-cols-4 gap-2">
              <FormField
                control={control}
                name={`other.${index}.name`}
                render={({ field }) => (
                  <FormItem>
                    {/* <FormLabel>{t("form.step.start")}</FormLabel> */}
                    <FormControl>
                      <InputField
                        sizes="md"
                        placeholder={t("form.step.start.placeholder")}
                        id={`name-${index}`}
                        type="text"
                        {...field}
                        disabled={disabled}
                        onFocus={clearErrors}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={control}
                name={`other.${index}.rate`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputField
                        sizes="md"
                        placeholder={t("form.step.end.placeholder")}
                        id={`rate-${index}`}
                        type="number"
                        {...field}
                        disabled={disabled}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name={`other.${index}.type`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <ComboBox
                        items={[
                          { label: "Water", value: "water" },
                          { label: "Electricity", value: "electricity" },
                          { label: "Sewerage", value: "sewerage" },
                          { label: "Other", value: "other" },
                        ]}
                        placeholder={t("form.placeholder.device.model")}
                        labelKey="label"
                        valueKey="value"
                        id="model"
                        type="text"
                        sizes="md"
                        {...field}
                        onFocus={clearErrors}
                        clearIcon={false}
                        disabled={disabled}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex items-center justify-between">
                <FormField
                  control={form.control}
                  name={`other.${index}.active`}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel></FormLabel>
                      <FormControl>
                        <Checkbox
                          id="vat-inclusive"
                          color="blue"
                          labelKey={field.value ? "Active" : "Inactive"}
                          onFocus={clearErrors}
                          checked={field.value}
                          returnBoolean
                          {...field}
                          disabled={disabled}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {!disabled && (
                  <CircleButton
                    icon={MdClose}
                    onClick={() => remove(index)}
                    disabled={disabled}
                    variant="outline"
                    size="sm"
                  />
                )}
              </div>
            </div>
          ))}
        </>

        {/* Submit Button */}
        {isDirty && !disabled && (
          <div className="flex w-full flex-col pt-4">
            <div className="flex justify-end">
              <GenericButton
                text={"Submit"}
                disabled={!isDirty}
                loading={loading || false}
                type="submit"
                size="md"
              />
            </div>
          </div>
        )}
      </form>
    </Form>
  );
};

export default OtherRateForm;
