import GenericButton from "widgets/shared/buttons/generic";

// Props
type AuthCardProps = {
  title?: string;
  description?: string;
  footer?: string;
  link?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

const AuthCard = ({
  title,
  description,
  footer,
  link,
  onClick,
  children,
}: AuthCardProps) => {
  return (
    <div className="flex h-full w-full">
      <div className="w-full flex-col space-y-6">
        <label className="heading-xl text-primary dark:text-darkPrimary">
          {title}
        </label>
        <p className="text-txt-sm text-tertiary dark:text-darkTertiary">
          {description}
        </p>
        {children}
        <div className="mt-4 flex items-baseline gap-1">
          <span className="heading-xs text-tertiary dark:text-darkTertiary">
            {footer}
          </span>
          <GenericButton
            size="xs"
            variant={"link"}
            onClick={onClick}
            text={link}
            extra="-ml-2"
          />
        </div>
      </div>
    </div>
  );
};

export default AuthCard;
