import { updateSite } from "api/site";
import { Site, SiteUpdateBody } from "api/types/site";
import { t } from "i18next";
import { useState } from "react";
import { MdEditDocument } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import GenericCard from "widgets/cards/generic";
import SiteConfigurationForm, {
  SiteConfigurationFormType,
} from "widgets/forms/sites/configuration";

interface GeneralProps {
  site?: Site;
  onUpdate?: () => void;
}

const Configuration: React.FC<GeneralProps> = ({ site, onUpdate }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);

  const doUpdateSite = async (formData: SiteConfigurationFormType) => {
    const body: SiteUpdateBody = {
      id: site?.id,
      rateStructureId: formData.rateStructureId,
      collector: formData.collector,
      billingPeriod: formData.billingPeriod,
      serviceLevel: formData.serviceLevel,
      reportEmail: formData.reportEmail,
    };

    dispatch(setLoading(true));
    const { status, isLoading } = await updateSite(body);
    dispatch(setLoading(isLoading));

    if (status && status === 200) {
      setEdit(false);
      onUpdate();
    }
  };

  return (
    <GenericCard
      title={t("device.configuration")}
      bgColor="bg-brand-400"
      // extra="h-full"
      extraTitle="text-secondary heading-md"
      topRight={
        <MdEditDocument
          className="heading-lg text-primary hover:cursor-pointer"
          onClick={() => {
            setEdit(!edit);
          }}
        />
      }
    >
      <SiteConfigurationForm
        values={{
          rateStructureId: site?.rateStructureId || "",
          collector: site?.collector || "",
          billingPeriod: site?.billingPeriod || "",
          serviceLevel: site?.serviceLevel || "",
          reportEmail: site?.reportEmail || "",
        }}
        submit={doUpdateSite}
        disabled={!edit}
        onClose={() => {}}
      />
    </GenericCard>
  );
};

export default Configuration;
