// Imports
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { t } from "i18next";
import { shallowEqual, useSelector } from "react-redux";
import { accountSiteList } from "store/appSelectors";
import MoreAction, { DropDownItem } from "widgets/moreAction";

// Define the structure of the JdTableModel
export class UserTableModel {
  email: string;
  role: string;
  id: string;
  classes: string[];
  actions: string;
}
const defineUserTablecolumns = (
  onEditClick?: (info: CellContext<UserTableModel, any>) => void,
  onRemoveClick?: (info: CellContext<UserTableModel, any>) => void
) => {
  // Initialize the translation hook and
  const moreMenuItems: DropDownItem[] = [
    {
      label: "Edit",
      active: true,
      onSelected: onEditClick,
    },
    // {
    //   label: "Remove",
    //   active: true,
    //   onSelected: onRemoveClick,
    // },
  ];
  // create a column helper for the JdTableModel
  const columnHelper = createColumnHelper<UserTableModel>();

  // Define the columns for the JdTable
  const userTablecolumns = [
    // Description column
    columnHelper.accessor("email", {
      enableSorting: true,
      header: () => <span className="">{t("table.header.site.name")}</span>,
      cell: (info) => <span className="">{info.getValue()}</span>,
      meta: { rowClick: true },
    }),

    // Status column
    columnHelper.accessor("role", {
      enableSorting: false,
      header: () => <span className="">{t("table.header.role")}</span>,
      cell: (info) => <span className="">{info.getValue()}</span>,
      meta: { rowClick: true },
    }),

    // Status column
    columnHelper.accessor("classes", {
      enableSorting: false,
      meta: { rowClick: true },
      header: () => <span className="">{t("table.header.sites")}</span>,
      cell: (info) => (
        <span className="flex flex-row gap-1 truncate">
          {info.getValue().length > 0 &&
            info.getValue().map((item) => {
              const sites = useSelector(accountSiteList, shallowEqual);
              const site = sites.find((a) => a.id === item);
              return site ? (
                <div
                  key={item} // Ensure each element has a unique key
                  className="flex items-center justify-center rounded-[10px] border px-2 py-1  uppercase"
                >
                  {site.name}
                </div>
              ) : null;
            })}
        </span>
      ),
    }),

    // Actions column
    columnHelper.accessor("actions", {
      enableSorting: false,
      meta: {
        rowClick: false,
        style: { textAlign: "center" },
      },
      header: () => <span className="">{t("table.header.actions")}</span>,
      cell: (info) => (
        <div>
          <MoreAction
            items={moreMenuItems}
            info={info}
            title="User Options"
            extra="w-full flex"
          />
        </div>
      ),
    }),
  ];

  return userTablecolumns;
};

export default defineUserTablecolumns;
