import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/modal";
import { DeviceGlobalSearchRes } from "api/types/device";
import { t } from "i18next";
import LabeledField from "providers/labeledField";
import { formatDate } from "util/datelib";

type DeviceGlobalSearchKeys = keyof DeviceGlobalSearchRes;

const GlobalSearchDialog = (props: {
  isOpen: boolean;
  onClose: () => void;
  device: DeviceGlobalSearchRes;
}) => {
  const deviceFields: {
    label: string;
    key: DeviceGlobalSearchKeys;
    isDate?: boolean;
  }[] = [
    { label: t("device.installer.account"), key: "account" },
    { label: t("device.installer.name"), key: "name" },
    { label: t("device.installer.snr"), key: "snr" },
    { label: t("device.installer.model"), key: "model" },
    { label: t("device.installer.meternumber"), key: "meternumber" },
    { label: t("device.installer.lastcomms"), key: "lastcomms" },
    { label: t("device.installer.created"), key: "created", isDate: true },
    { label: t("device.installer.site"), key: "site" },
  ];

  return (
    <>
      <Modal
        isCentered
        isOpen={props.isOpen}
        onClose={props.onClose}
        closeOnOverlayClick
        onOverlayClick={props.onClose}
      >
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[22vh] !m-auto !w-max !max-w-[40%] rounded-xl bg-white p-5 md:top-[12vh]">
          <ModalHeader className="mb-4 text-2xl text-primary">
            Global Search Result
          </ModalHeader>
          <ModalBody className="flex flex-col gap-2">
            <div className="grid grid-cols-1 gap-1 md:gap-2">
              {deviceFields.map((field) => (
                <LabeledField
                  //   label={`${field.label}:`}
                  label={"Item: "}
                  id={field.key}
                  key={field.key}
                >
                  <label
                    className={`whitespace-normal break-words text-txt-md text-tertiary`}
                  >
                    {field.isDate
                      ? formatDate(props.device?.[field.key])
                      : props.device?.[field.key] || "-"}
                  </label>
                </LabeledField>
              ))}
            </div>
          </ModalBody>
          <ModalFooter className="mt-4 gap-2"></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GlobalSearchDialog;
