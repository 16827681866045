export const ADMIN = {
  ADMIN: {
    PHOTO: {
      GENERATE_SIGNED_URL: "admin/photo/generate-signed-url",
    },
    UVS: {
      UPLOAD: "admin/uvs/upload/xlsx",
      DISBURSEMENTS: "admin/uvs/disbursements/",
    },
  },
};
