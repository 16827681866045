export const DEVICE = {
  DEVICE: {
    PAGE: "device/page",
    ADD: "device/add",
    DELETE: "device/delete",
    DELETE_MANY: "device/delete/many",
    GET: "device/get",
    SET: "device/set",
    LIST: "device/list",
    GLOBAL: {
      SEARCH: "device/global/search",
    },
    DATA_QUALITY: {
      SET: "device/data-quality/set",
    },
  },
};
