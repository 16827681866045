import { shallowEqual, useSelector } from "react-redux";
import BounceLoader from "react-spinners/ClipLoader";
import { RootState } from "store/store";

export const Spinner = (props: {}) => {
  const { loading } = useSelector(
    (state: RootState) => state.app,
    shallowEqual
  );

  if (!loading) return null;

  return (
    <div className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-50">
      <BounceLoader loading={loading} />
    </div>
  );
};
