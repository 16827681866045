import { ViewPort } from "models/constants/styling";
import Configuration from "./components/configuration";
import Viewer from "./components/viewer";
import { useState } from "react";

const Reports = () => {
  const [data, setData] = useState<any>(null);
  return (
    <div
      className={`mt-6 grid w-full grid-cols-1 gap-y-4 ${ViewPort.contentFitHeight}`}
    >
      <div className="flex flex-col gap-4 lg:flex-row">
        {/* Group for CARD 1, 2, and 3 taking 2/3 of the row */}
        <div className="flex flex-col gap-4 lg:w-1/3">
          <Configuration
            onData={(data) => {
              setData(data);
            }}
          />
        </div>
        {/* CARD 4 taking 1/3 of the row */}
        <div className="lg:w-2/3">
          <Viewer data={data} />
        </div>
      </div>
    </div>
  );
};

export default Reports;
