export const AddDeviceDropdown = [
  {
    name: "Sigfox Dry Contact Tag",
    code: "tag.dry",
  },
  {
    name: "Sigfox Temperature Tag",
    code: "tag.tmp",
  },
  {
    name: "Lora Nano Tag",
    code: "lora.nanotag",
  },
  {
    name: "Lora Temperature Tag",
    code: "lora.tmp",
  },
  {
    name: "RF Temperature Tag",
    code: "tag.tmo",
  },
  {
    name: "Manual Water Meter",
    code: "qrw.man",
  },
  {
    name: "Manual Electric Meter",
    code: "qre.man",
  },
  {
    name: "Smart Water Meter",
    code: "tag.rhw",
  },
  {
    name: "Smart Valve Water Meter (Discontinued) ",
    code: "tag.h2o",
  },
  {
    name: "Smart Electricity Meter",
    code: "tag.rhe",
  },
  {
    name: "Smart Geyser",
    code: "atu.log",
  },
  {
    name: "Optical Electricity Meter",
    code: "tag.opt",
  },
  {
    name: "Prepaid Water Meter",
    code: "qrw.pre",
  },
  {
    name: "Prepaid Electricity Meter",
    code: "qre.pre",
  },
  {
    name: "Prepaid Gas Meter",
    code: "qrg.pre",
  },
  {
    name: "Smart Valve Water Meter V2",
    code: "tag.wmd",
  },
  {
    name: "Lora WMD",
    code: "lora.wmd",
  },
  {
    name: "Smart Level Sensor",
    code: "tag.lvl",
  },
  {
    name: "Watermeter (Romulus)",
    code: "rom.ptw",
  },
  {
    name: "Romulus Electricity meter (Pool)",
    code: "rom.pte",
  },
  {
    name: "Romulus Electricity meter (RH)",
    code: "rom.rhe",
  },
];
