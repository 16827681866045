import { RootState } from "../store";

export const userEmail = (state: RootState) => state.user.email;
export const userName = (state: RootState) => state.user.name;
export const userFullname = (state: RootState) =>
  `${state.user.name} ${state.user.family_name}`;

export const userInformation = (state: RootState) => {
  return {
    email: state.user.email,
    name: state.user.name,
    family_name: state.user.family_name,
    phone_number: state.user.phone_number,
  };
};

export const userMfaEnabled = (state: RootState) => state.user.mfa_enabled;

export const jwtAccessToken = (state: RootState) => state.user.jwtAccessToken;
