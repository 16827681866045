export const InstalledDropdown = [
  {
    label: "INSTALLED",
    value: "INSTALLED",
  },
  {
    label: "NOT INSTALLED",
    value: "NOT INSTALLED",
  },
  {
    label: "BOTH",
    value: "BOTH",
  },
];
