export const CollectorDropdown = [
  {
    label: "ATOM",
    value: "ATOM",
  },
  {
    label: "VACO",
    value: "VACO",
  },
];
