export const REPORT = {
  REPORT: {
    RAW: "report/raw",
    AGGREGATE: "report/aggregate",
    DATA: {
      WATER: {
        BASIC: "report/data/water/basic",
      },
    },
  },
};
