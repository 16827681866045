import Checkbox from "components/checkbox";
import ProfileCard from "widgets/cards/profile";
import SubmitButton from "widgets/shared/buttons/submit";
import ComboBox from "widgets/shared/inputs/combobox";
import InputField from "widgets/shared/inputs/inputField";
import MultiCombobox from "widgets/shared/inputs/multiselectCombobox";
import PhoneNumber from "widgets/shared/inputs/phoneNumber";
import PinInputs from "widgets/shared/inputs/pinInput";
import { SearchInput } from "widgets/shared/inputs/search";
import ExampleForm from "./components/form";
import GenericButton from "widgets/shared/buttons/generic";
import { notifySimple } from "providers/toast";
import LabeledField from "providers/labeledField";

const Widgets = () => {
  return (
    <div className="mt-6 grid h-full w-full grid-cols-1 gap-y-4 lg:grid-cols-2 lg:gap-x-4 lg:gap-y-4">
      <ProfileCard title="Inputs" description="Example">
        <div className="flex flex-col gap-2">
          <InputField id={"1"} placeholder="label..." sizes="lg" />
          <InputField id={"1"} placeholder="label..." sizes="md" />
          <InputField id={"1"} placeholder="label..." sizes="sm" />
          <InputField id={"1"} placeholder="label..." sizes="xs" disabled />
          <LabeledField label={"Username"} id={"pin"}>
            <InputField id={"1"} placeholder="label..." sizes="lg" />
          </LabeledField>
          <PhoneNumber
            id={""}
            value={""}
            placeholder="7373737"
            onChang={() => {}}
          />
          <SearchInput value={""} onChange={() => {}} />
          <Checkbox />
          <ComboBox
            id={""}
            value={"super"}
            onChange={(val) => {
              console.log(val);
            }}
            items={[
              {
                value: "super",
                label: "Super",
              },
              {
                value: "administrator",
                label: "Administrator",
              },
              {
                value: "owner",
                label: "Owner",
              },
              {
                value: "manager",
                label: "Manager",
              },
              {
                value: "user",
                label: "User",
              },
            ]}
            placeholder={"comboxbox..."}
          />
          <MultiCombobox
            value={[]}
            onChange={() => {}}
            items={[]}
            placeholder={"combobox multi select..."}
            id={""}
          />
          <PinInputs value={""} onChange={() => {}} />
          <div>Radio Buttons</div>
          <div>TextArea</div>
          <div>Date Picker</div>
          <div>Date Range Picker</div>
          <div>Time Picker</div>
          <div>DateTime Picker</div>
          <div>Multiple checkboxes Single/Multi select</div>
        </div>
      </ProfileCard>
      <ProfileCard title="Form" description="Example">
        <ExampleForm
          submit={(value) => {
            console.log(value);
          }}
        />
      </ProfileCard>
      <ProfileCard title="Buttons" description="Example">
        <SubmitButton variant={"default"} text={"Submit"} />
      </ProfileCard>
      <ProfileCard title="Text" description="Typography">
        <>To Do</>
      </ProfileCard>
      <ProfileCard title="Dialogs" description="Example">
        <>To Do</>
      </ProfileCard>
      <ProfileCard title="Toaster" description="Examples">
        <div className="mt-4 flex gap-5">
          <GenericButton
            variant={"solid"}
            size="lg"
            text={"Success"}
            onClick={() => {
              notifySimple("Success", "success");
            }}
          />
          <GenericButton
            variant={"solid"}
            size="md"
            text={"Error"}
            onClick={() => {
              notifySimple("Error", "error");
            }}
            disabled
          />
          <GenericButton
            variant={"solid"}
            size="sm"
            text={"Warning"}
            onClick={() => {
              notifySimple("Warning", "warning");
            }}
          />
          <GenericButton
            variant={"solid"}
            size="xs"
            text={"Info"}
            onClick={() => {
              notifySimple("Success", "info");
            }}
          />
        </div>
        <div className="mt-4 flex gap-5">
          <GenericButton
            variant={"outline"}
            size="lg"
            text={"Success"}
            onClick={() => {
              notifySimple("Success", "success");
            }}
          />
          <GenericButton
            variant={"outline"}
            size="md"
            text={"Error"}
            onClick={() => {
              notifySimple("Error", "error");
            }}
          />
          <GenericButton
            variant={"outline"}
            size="sm"
            text={"Warning"}
            onClick={() => {
              notifySimple("Warning", "warning");
            }}
          />
          <GenericButton
            variant={"outline"}
            size="xs"
            text={"Info"}
            onClick={() => {
              notifySimple("Success", "info");
            }}
          />
        </div>
        <div className="mt-4 flex gap-5">
          <GenericButton
            variant={"ghost"}
            size="lg"
            text={"Success"}
            onClick={() => {
              notifySimple("Success", "success");
            }}
          />
          <GenericButton
            variant={"ghost"}
            size="md"
            text={"Error"}
            onClick={() => {
              notifySimple("Error", "error");
            }}
          />
          <GenericButton
            variant={"ghost"}
            size="sm"
            text={"Warning"}
            onClick={() => {
              notifySimple("Warning", "warning");
            }}
          />
          <GenericButton
            variant={"ghost"}
            size="xs"
            text={"Info"}
            onClick={() => {
              notifySimple("Success", "info");
            }}
          />
        </div>
        <div className="mt-4 flex gap-5">
          <GenericButton
            variant={"link"}
            size="lg"
            text={"Success"}
            onClick={() => {
              notifySimple("Success", "success");
            }}
          />
          <GenericButton
            variant={"link"}
            size="md"
            text={"Error"}
            onClick={() => {
              notifySimple("Error", "error");
            }}
          />
          <GenericButton
            variant={"link"}
            size="sm"
            text={"Warning"}
            onClick={() => {
              notifySimple("Warning", "warning");
            }}
          />
          <GenericButton
            variant={"link"}
            size="xs"
            text={"Info"}
            onClick={() => {
              notifySimple("Success", "info");
            }}
          />
        </div>
      </ProfileCard>
      <ProfileCard title="Loaders" description="Example">
        <>To Do</>
      </ProfileCard>
    </div>
  );
};

export default Widgets;
