const SubmitButton = (props: {
  loading?: boolean;
  disabled?: boolean;
  variant: "default" | "auth";
  size?: "lg" | "xs";
  text: string;
}) => {
  let typeClass = "";

  switch (props.variant) {
    case "auth":
      typeClass = "flex items-center justify-center w-full linear rounded-xl";
      break;
    default:
      typeClass =
        "flex items-center justify-center w-full linear rounded-xl bg-blue-500 px-5 py-2 text-base font-medium text-white transition duration-200 disabled:bg-gray-200 hover:bg-blue-400 active:bg-blue-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30";
      break;
  }

  return (
    <button
      className={`linear flex h-12 w-full items-center justify-center rounded-2xl bg-brand-500 px-4 py-6 text-white`}
      disabled={props.loading || props.disabled}
      type='submit'
    >
      {props.text}
    </button>
  );
};

export default SubmitButton;
