import { background } from "@chakra-ui/system";
import { dataUsage } from "api/data";
import { DataReqBody } from "api/types/data";
import { Device } from "api/types/device";
import BarChart from "components/charts/BarChart";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { formatDate, formatDateToZ } from "util/datelib";
import GenericCard from "widgets/cards/generic";
import Combobox from "widgets/shared/inputs/combobox";
import DatePickerField from "widgets/shared/inputs/datePicker";
import PropagateLoader from "react-spinners/PropagateLoader";
import WaterRateForm from "widgets/forms/sites/rates/water";
import SewerageRateForm from "widgets/forms/sites/rates/sewerage";
import ElectricityRateForm from "widgets/forms/sites/rates/electricity";
import LabeledField from "providers/labeledField";
import { Site } from "api/types/site";
import { setLoading } from "store/appSlice";
import { ratesRateGetActive } from "api/rates";
import { RateResponse } from "api/types/rates";
import OtherRateForm from "widgets/forms/sites/rates/other";

interface GeneralProps {
  site?: Site;
  onUpdate?: () => void;
}

const Rates: React.FC<GeneralProps> = ({ site, onUpdate }) => {
  const dispatch = useDispatch();

  const [rates, setRates] = useState<RateResponse>(null);

  const fetchData = async () => {
    dispatch(setLoading(true));
    const { data, status, isLoading } = await ratesRateGetActive(site?.id);
    dispatch(setLoading(isLoading));
    if (status && status === 200) {
      setRates(data.rates);
    }
  };

  useEffect(() => {
    if (site && site.id) {
      setRates(null);
      fetchData();
    }
  }, [site]);

  return (
    <GenericCard
      title="Rates"
      description={"Display rates"}
      bgColor="bg-white"
      // extra="h-full"
      extraTitle="text-primary heading-md"
      extraDescription="text-tertiary text-txt-sm"
    >
      <div className="flex h-full w-full flex-col gap-6 overflow-x-auto">
        {rates && (
          <>
            <LabeledField label={"Water Rates"} id={""} className="heading-lg">
              <WaterRateForm
                values={rates?.water}
                submit={() => {}}
                onClose={() => {}}
                disabled
              />
            </LabeledField>
            <LabeledField
              label={"Sewerage Rates"}
              id={""}
              className="heading-lg"
            >
              <SewerageRateForm
                values={rates?.sewerage}
                submit={() => {}}
                onClose={() => {}}
                disabled
              />
            </LabeledField>
            <LabeledField
              label={"Electricity Rates"}
              id={""}
              className="heading-lg"
            >
              <ElectricityRateForm
                values={rates?.electricity}
                submit={() => {}}
                onClose={() => {}}
                disabled
              />
            </LabeledField>
            {rates.other && (
              <LabeledField
                label={"Other Rates"}
                id={""}
                className="heading-lg"
              >
                <OtherRateForm
                  submit={() => {}}
                  onClose={() => {}}
                  disabled
                  values={{ other: rates?.other }}
                />
              </LabeledField>
            )}
          </>
        )}
      </div>
    </GenericCard>
  );
};

export default Rates;
