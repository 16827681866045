import React from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Circle,
} from "@react-pdf/renderer";
import CircleButton from "widgets/shared/buttons/circle-button";
import { MdFileDownload } from "react-icons/md";
import { ReportResponse } from "api/types/report";
import Default from "assets/img/account/Diploma.png";
import { formatDate } from "util/datelib";
import * as XLSX from "xlsx";

Font.register({
  family: "Helvetica",
  src: "assets/fonts/Helvetica-Bold.ttf", // Replace with the correct path to your font file
});

// Define styles for the PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 40,
    paddingBottom: 40,
    paddingHorizontal: 20,
    backgroundColor: "#FFFFFF",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    fontSize: 10,
    fontWeight: 600,
    fontFamily: "Helvetica",
  },
  footerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    padding: 10,
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: 10,
    fontWeight: 600,
    fontFamily: "Helvetica",
  },
  leftText: {
    flex: 1,
    textAlign: "left",
  },
  centerText: {
    flex: 1,
    textAlign: "center",
  },
  rightText: {
    flex: 1,
    textAlign: "right",
    justifyContent: "center",
    alignItems: "flex-end",
  },

  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 0, // spacing between rows
  },
  label: {
    width: 120, // set a fixed width for labels to align values consistently
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
    fontFamily: "Helvetica",
  },
  value: {
    flex: 1, // takes up remaining space
    textAlign: "left",
    fontSize: 10,
    fontFamily: "Helvetica",
  },

  title: {
    fontSize: 18,
    marginTop: 10,
    fontWeight: 600,
    fontFamily: "Helvetica",
  },
  title0: {
    fontSize: 14,
    marginTop: 10,
    fontWeight: 600,
    fontFamily: "Helvetica",
  },
  subTitle: { fontSize: 10, fontFamily: "Helvetica" },
  image: {
    width: 100,
    height: 100,
  },

  table: {
    marginTop: 10,
    width: "100%",
  },
  tableHeader: {
    flexDirection: "row",
    paddingBottom: 5,
    marginBottom: 5,
    fontWeight: 600,
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    fontFamily: "Helvetica",
  },
  tableRow: {
    flexDirection: "row",
    paddingVertical: 1,
  },
  tableCol: {
    minWidth: 80, // Minimum column width
    flex: 1, // Allows columns to expand evenly
    // padding: 5, // Padding inside cells for readability
    paddingRight: 2,
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Helvetica",
    textAlign: "left", // Align text to the left
    overflow: "hidden", // Ensures no content overflows
  },
});

const formatValue = (header: any, value: string | number | Date) => {
  switch (header) {
    case "created":
      return formatDate(new Date(value), "lastcomms"); // Format the date and convert it to a string

    default:
      return String(value);
  }
};

// Export data to CSV
// Export data to CSV with titleContainer values as headers
const exportToCSV = (data: any, reportDetails: any) => {
  if (!data) return;

  // Define titleContainer values as headers
  const titleHeaders = [
    ["Account Name:", reportDetails?.accountName || ""],
    ["Site Name:", reportDetails?.siteName || ""],
    [
      "From Date:",
      reportDetails?.fromDate
        ? formatDate(reportDetails.fromDate, "ddMMMyyyy")
        : "",
    ],
    [
      "To Date:",
      reportDetails?.toDate
        ? formatDate(reportDetails.toDate, "ddMMMyyyy")
        : "",
    ],
    ["Device Details:", reportDetails?.deviceDetails?.meterName || ""],
  ]
    .map(([label, value]) => `${label}, ${value}`)
    .join("\n");

  // Extract column headers from data
  const headers = Object.keys(data[0]).join(",") + "\n";
  const rows = data
    .map((item: { [s: string]: unknown } | ArrayLike<unknown>) =>
      Object.values(item).join(",")
    )
    .join("\n");

  const csvContent = `${titleHeaders}\n\n${headers}${rows}`;

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "data.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const exportToExcel = (data: any, reportDetails: any) => {
  if (!data) return;

  // Define titleContainer values as headers
  const titleHeaders = [
    ["Account Name:", reportDetails?.accountName || ""],
    ["Site Name:", reportDetails?.siteName || ""],
    [
      "From Date:",
      reportDetails?.fromDate
        ? formatDate(reportDetails.fromDate, "ddMMMyyyy")
        : "",
    ],
    [
      "To Date:",
      reportDetails?.toDate
        ? formatDate(reportDetails.toDate, "ddMMMyyyy")
        : "",
    ],
    ["Device Details:", reportDetails?.deviceDetails?.meterName || ""],
  ];

  // Create a new worksheet
  const wsData = [
    ...titleHeaders, // Title headers
    [], // Empty row for spacing
    Object.keys(data[0]), // Data headers
    ...data.map((item: { [s: string]: unknown }) => Object.values(item)), // Data rows
  ];

  const ws = XLSX.utils.aoa_to_sheet(wsData);

  // Create a new workbook with the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Report");

  // Generate Excel file
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // Create a Blob from the Excel data and download it
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "data.xlsx");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

interface MyDocumentProps {
  report: ReportResponse;
}

const ReportViewer: React.FC<MyDocumentProps> = ({ report }) => {
  if (!report) {
    // Display a fallback UI if report is undefined
    return <Text></Text>;
  }

  const { reportDetails, header, footer, data } = report;
  // Dynamically retrieve headers from the first item
  const items = data || [];
  const deviceDetails = reportDetails?.deviceDetails || {};
  const headers = Object.keys(items[0] || {});

  return (
    <>
      {items.length > 0 && headers.length > 0 && (
        <>
          <div className="mb-2 flex justify-end gap-2">
            <CircleButton
              size="sm"
              icon={MdFileDownload}
              label={"Export to CSV"}
              onClick={() => exportToCSV(items, reportDetails)}
            />
            <CircleButton
              size="sm"
              icon={MdFileDownload}
              label={"Export to Excel"}
              onClick={() => exportToExcel(items, reportDetails)}
            />
          </div>
          <PDFViewer style={{ width: "100%", height: "80vh" }}>
            <Document title="HELLO">
              <Page style={styles.page} size={"A4"} orientation="landscape">
                {/* Header */}
                <View style={styles.headerContainer} fixed>
                  <Text style={styles.leftText}>{header.snrOrSite}</Text>
                  <Text style={styles.centerText}>{header.title}</Text>
                  <Text style={styles.rightText}>{header.accountName}</Text>
                </View>

                {/* Title (Outside Header) */}
                <View style={styles.titleContainer}>
                  <View style={styles.leftText}>
                    {reportDetails?.title && (
                      <Text style={styles.title}>{reportDetails.title}</Text>
                    )}

                    <View style={styles.row}>
                      {reportDetails?.accountName && (
                        <>
                          <Text style={styles.label}>Account Name:</Text>
                          <Text style={styles.value}>
                            {reportDetails.accountName}
                          </Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {reportDetails?.siteName && (
                        <>
                          <Text style={styles.label}>Site Name:</Text>
                          <Text style={styles.value}>
                            {reportDetails.siteName}
                          </Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {reportDetails?.fromDate && (
                        <>
                          <Text style={styles.label}>From Date:</Text>
                          <Text style={styles.value}>
                            {formatDate(reportDetails.fromDate, "ddMMMyyyy")}
                          </Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {reportDetails?.toDate && (
                        <>
                          <Text style={styles.label}>To Date:</Text>
                          <Text style={styles.value}>
                            {formatDate(reportDetails.toDate, "ddMMMyyyy")}
                          </Text>
                        </>
                      )}
                    </View>

                    <Text style={styles.title0}>Device Details:</Text>

                    <View style={styles.row}>
                      {deviceDetails?.meterName && (
                        <>
                          <Text style={styles.label}>Meter Name:</Text>
                          <Text style={styles.value}>
                            {deviceDetails.meterName}
                          </Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {deviceDetails?.meterNumber && (
                        <>
                          <Text style={styles.label}>Meter Number:</Text>
                          <Text style={styles.value}>
                            {deviceDetails.meterNumber}
                          </Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {deviceDetails?.snr && (
                        <>
                          <Text style={styles.label}>Serial Number:</Text>
                          <Text style={styles.value}>{deviceDetails.snr}</Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {deviceDetails?.lastComms && (
                        <>
                          <Text style={styles.label}>Last Comms:</Text>
                          <Text style={styles.value}>
                            {formatDate(deviceDetails.lastComms, "lastcomms")}
                          </Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {deviceDetails?.deviceCount && (
                        <>
                          <Text style={styles.label}>Dev Count:</Text>
                          <Text style={styles.value}>
                            {deviceDetails.deviceCount}
                          </Text>
                        </>
                      )}
                    </View>
                  </View>
                  <View style={styles.rightText}>
                    <Image
                      src={reportDetails?.logo || Default}
                      style={styles.image}
                    />
                  </View>
                </View>

                <View style={styles.table}>
                  {/* Table Header */}
                  <View style={styles.tableHeader} fixed>
                    {headers.map((header, index) => (
                      <Text key={index} style={styles.tableCol}>
                        {header
                          .replace(/-/g, " ")
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </Text>
                    ))}
                  </View>

                  {/* Table Rows */}
                  {items.map((item, rowIndex) => (
                    <View style={styles.tableRow} key={rowIndex}>
                      {headers.map((header, colIndex) => (
                        <Text key={colIndex} style={styles.tableCol}>
                          {formatValue(header, item[header])}
                        </Text>
                      ))}
                    </View>
                  ))}
                </View>

                {/* Footer */}
                <View style={styles.footerContainer} fixed>
                  <Text style={styles.leftText}>{footer.siteName}</Text>
                  <Text
                    render={({ pageNumber, totalPages }) =>
                      `Page ${pageNumber} of ${totalPages}`
                    }
                    fixed
                    style={styles.centerText}
                  />
                  <Text style={styles.rightText}></Text>
                </View>
              </Page>

              {/* <Page style={styles.page} size={"A4"} orientation="portrait">
            
                <View style={styles.headerContainer} fixed>
                  <Text style={styles.leftText}>Snr: Sig00c6464</Text>
                  <Text style={styles.centerText}>Water Meter Data Report</Text>
                  <Text style={styles.rightText}>Company Name</Text>
                </View>

                <Text>Appendices</Text>
           
                <View style={styles.footerContainer} fixed>
                  <Text style={styles.leftText}>Snr: Sig00c6464</Text>
                  <Text
                    render={({ pageNumber, totalPages }) =>
                      `Page ${pageNumber} of ${totalPages}`
                    }
                    fixed
                    style={styles.centerText}
                  />
                  <Text style={styles.rightText}>Company Name</Text>
                </View>
              </Page> */}
            </Document>
          </PDFViewer>
        </>
      )}
    </>
  );
};

export default ReportViewer;
