import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import InputField from "widgets/shared/inputs/inputField";
import GenericButton from "widgets/shared/buttons/generic";

// Schema
const ForgotPasswordSchema = z.object({
  email: z
    .string()
    .min(1, { message: i18next.t("zod.error.required") })
    .email({ message: i18next.t("zod.error.email") }),
});

export type ForgotPasswordFormType = z.infer<typeof ForgotPasswordSchema>;

// Props
type ForgotPasswordFormProps = {
  submit: (data: ForgotPasswordFormType) => void;
};

const ForgotPasswordForm = ({ submit }: ForgotPasswordFormProps) => {
  const form = useForm<ForgotPasswordFormType>({
    resolver: zodResolver(ForgotPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const { t } = useTranslation();
  const { errors } = form.formState;

  const onSubmit = async (formData: ForgotPasswordFormType) => {
    try {
      await submit(formData);
    } catch (error) {}
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-4">
          <FormField
            control={form.control}
            name="email"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.email")}</FormLabel>
                <FormControl>
                  <InputField
                    variant="auth"
                    placeholder={t("form.placeholder.email")}
                    id="username"
                    type="text"
                    state={errors.email ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <GenericButton
          type="submit"
          text={t("auth.forgot.password.action")}
          extra="w-full"
        />
      </form>
    </Form>
  );
};

export default ForgotPasswordForm;
