import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import GenericButton from "widgets/shared/buttons/generic";
import { useEffect } from "react";
// Schema
const SiteGeneralSchema = z.object({
  name: z.string(),
  reference: z.string(),
  siteArea: z.string(),
  siteId: z.string(),
});

export type SiteGeneralFormType = z.infer<typeof SiteGeneralSchema>;

// Props
type SiteGeneralFormProps = {
  submit: (data: SiteGeneralFormType) => void;
  onClose: () => void;
  defaultValues?: SiteGeneralFormType;
  values?: SiteGeneralFormType;
  loading?: boolean;
  disabled?: boolean;
};

const SiteGeneralForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
  disabled,
}: SiteGeneralFormProps) => {
  const form = useForm<SiteGeneralFormType>({
    resolver: zodResolver(SiteGeneralSchema),
    defaultValues: {
      name: "",
      reference: "",
      siteArea: "",
      siteId: "",
    },
    values,
  });

  const { t } = useTranslation();
  const { errors, isDirty } = form.formState;

  const clearErrors = () => {
    form.clearErrors();
  };

  // Automatically submit the form if valid
  const onSubmit = async (formData: SiteGeneralFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    if (disabled && isDirty) {
      form.reset(values || defaultValues);
    }
  }, [disabled, isDirty, form, values, defaultValues]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-5">
          <FormField
            control={form.control}
            name="name"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">
                  {t("form.input.site.name")}
                </FormLabel>
                <FormControl>
                  <InputField
                    sizes={"md"}
                    placeholder={t("form.placeholder.site.name")}
                    id="name"
                    type="text"
                    state={errors.name ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled}
                    {...field}
                    extra="bg-secondary"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="reference"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">
                  {t("form.input.site.reference")}
                </FormLabel>
                <FormControl>
                  <InputField
                    sizes={"md"}
                    placeholder={t("form.placeholder.site.reference")}
                    id="reference"
                    type="text"
                    state={errors.reference ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled}
                    {...field}
                    extra="bg-secondary"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-5">
          <FormField
            control={form.control}
            name="siteArea"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">{"Site Area"}</FormLabel>
                <FormControl>
                  <InputField
                    sizes={"md"}
                    placeholder={t("form.placeholder.site.area")}
                    id="site-area"
                    type="text"
                    state={errors.siteArea ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled}
                    {...field}
                    extra="bg-secondary"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="siteId"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">{"Site Id"}</FormLabel>
                <FormControl>
                  <InputField
                    sizes={"md"}
                    placeholder={t("form.placeholder.site.id")}
                    id="site-id"
                    type="text"
                    state={errors.siteId ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled}
                    {...field}
                    extra="bg-secondary"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        {isDirty && !disabled && (
          <div className="flex w-full flex-col pt-4">
            <div className="flex justify-end">
              <GenericButton
                text={t("button.action.save.changes")}
                disabled={!isDirty}
                loading={loading || false}
                type="submit"
              />
            </div>
          </div>
        )}
      </form>
    </Form>
  );
};

export default SiteGeneralForm;
