import * as React from "react";
import { forwardRef, ReactNode } from "react";

interface LabeledFieldProps {
  label: string;
  id: string;
  children: ReactNode;
  containerClassName?: string;
  className?: string;
}

const LabeledField = forwardRef<HTMLDivElement, LabeledFieldProps>(
  ({ label, id, children, className, containerClassName }, ref) => {
    return (
      <div ref={ref} className={`space-y-2 ${containerClassName}`}>
        <label
          htmlFor={id}
          className={`text-txt-md text-primary dark:text-darkPrimary ${className}`}
        >
          {label}
        </label>
        {children}
      </div>
    );
  }
);

LabeledField.displayName = "LabeledField";

export default LabeledField;
